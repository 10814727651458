import React, { useState } from "react"
import { useWindowSize } from '@react-hook/window-size'
import { Row, Col } from "antd"

import TweetThemeChart from './TweetThemesChart'
import politicThemes from '../../assets/data/politicThemes.json'
import './TweetThemes.css'


function TweetThemesDetails() {
  const [width] = useWindowSize();
  const [referenceThemes] = useState(politicThemes)

  return (
    <div style={{ marginBottom: 40 }}>
      <Row type="flex">
        <Col span={ width >= 900 ? 22 : 22} offset={ width >= 900 ? 0 : 1}>
          <h1>Thématiques des présidents de partis</h1>
          <p>Quels sont les thèmes qui occupent le plus de place dans le fil Twitter des présidents de parti ? Comment se démarquent-ils les uns des autres ?</p>
        </Col>
      </Row>
      <div> 
        <TweetThemeChart 
          marginBottom={40}
          title={'Ecologie'}
          text={<div>Certains thèmes clés de la ligne idéologique de chaque parti ressortent clairement dans leurs discours. Ainsi, <strong>Jean-Marc Nollet</strong> (Ecolo) et <strong>Meryem Almaci</strong> (Groen) sont ceux qui consacrent proportionnellement le plus de tweets aux questions écologiques et climatiques.</div>}
          themeData={referenceThemes.find(t => t.theme === 'écologie')}
        />
        <TweetThemeChart 
          marginBottom={80}
          title={'Sécurité et justice'}
          text={<div><strong>Bart De Wever</strong> (N-VA) et <strong>Tom Van Grieken</strong> (Vlaams Belang) sont ceux qui consacrent une plus grande partie de leurs tweets aux questions de sécurité et de justice.</div>}
          themeData={referenceThemes.find(t => t.theme === 'sécurité_justice')}
        />
        <TweetThemeChart 
          marginBottom={20}
          title={'Emploi et pensions'}
          text={<div>Le thème de l’emploi et des pensions est particulièrement présent dans les tweets des présidents des partis de gauche (PTB, PS et SP.A). C’est <strong>Raoul Hedebouw</strong> (PTB) qui y consacre la plus grande partie de ses messages.</div>}
          themeData={referenceThemes.find(t => t.theme === 'emploi_pensions')}
        />
      </div>
    </div>
  );
}

export default TweetThemesDetails;
