import React, { Component } from 'react'
import { Row } from 'antd'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

export default class Share extends Component {
  state = {};

  render() {
    const url = 'https://www.rtbf.be/info/belgique/detail_les-reseaux-sociaux-un-lieu-approprie-pour-parler-de-politique-voici-comment-les-presidents-de-parti-utilisent-twitter-grand-format?id=10635451'
    return (
      <Row type="flex" gutter={16}>
        <TwitterShareButton url={url} title="Comment les présidents de partis belges utilisent-ils Twitter ?" via="rtbfinfo" hashtags={["Twitter", "Politique", "Belgique"]}>
          <TwitterIcon size={36} round={true}/>
        </TwitterShareButton>
        <LinkedinShareButton url={url} title="Comment les présidents de partis belges utilisent-ils Twitter ?">
          <LinkedinIcon size={36} round={true}/>
        </LinkedinShareButton>
        <FacebookShareButton url={url} quote="Comment les présidents de partis belges utilisent-ils Twitter ?"> 
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
        <EmailShareButton url={url} subject="Comment les présidents de partis belges utilisent-ils Twitter ?"> 
          <EmailIcon size={36} round={true} />
        </EmailShareButton>
      </Row>
    );
  }
}