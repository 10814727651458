import React from "react"
import { Row, Col } from 'antd'
import { useWindowWidth } from '@react-hook/window-size'

import BadgeList from '../Sections/BadgeList'
import BadgeTop from '../Sections/BadgeTop'
import PresidentsScatter from '../Sections/PresidentsScatter'
import TweetThemesDetails from '../TweetThemes/TweetThemesDetails'
import TweetThemesGlobal from '../TweetThemes/TweetThemesGlobal'
import mainImg from '../../assets/images/main.jpg'
import Intro from '../Sections/Intro'
import Share from '../Share/Share'

import rtbf from '../../assets/images/rtbf.png'
import jetpack from '../../assets/images/JetpackAI-small.png'
import datatext from '../../assets/images/datatext.png'

function MainLayout() {
  const width = useWindowWidth({wait: 1})
  return (
    <div>
      { width >= 900 &&
        <div style={{ position: 'relative' }}>
          <div>
            <div style={{ backgroundColor: '#2AA3F0', height: '10vh'}}></div>
            <img alt='main-img' src={mainImg} style={{ width: '100%', marginBottom: '5vw' }}/>
          </div>
          <div style={{ position: 'absolute', width: '100%', color: 'white', top: '5vh', textAlign: 'center' }}>
            {/*<div style={{ fontSize: '7vw', fontFamily: "Playfair Display" }}>Politweets</div>*/}
            <div style={{ fontSize: '5vw', fontFamily: "Playfair Display", marginBottom: '5vh' }}>Comment les présidents de partis belges utilisent-ils Twitter ?</div>
            <h3 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/AmCaW" target="_blank" rel="noopener noreferrer" alt="Carton">Ambroise Carton</a></strong> – Journaliste @RTBF</h3>
            <h3 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/karim_douieb" target="_blank" rel="noopener noreferrer" alt="Douieb">Karim Douieb</a></strong> – Data Visualisation <a href="https://jetpack.ai" style={{color: 'white'}} target="_blank" rel="noopener noreferrer" alt="jetpack">@Jetpack.AI</a></h3>
            <h3 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/devironl" target="_blank" rel="noopener noreferrer" alt="Viron">Louis de Viron</a></strong> – Linguiste et Data Scientist <a href="https://datatext.eu" style={{color: 'white'}} target="_blank" rel="noopener noreferrer" alt="datatext">@DataText</a></h3>
          </div>
        </div>
      }
      { width < 900 &&
        <div style={{ position: 'relative' }}>
          <div>
            <div style={{ backgroundColor: '#2AA3F0', height: '35vh'}}></div>
            <img alt='main-img' src={mainImg} rel="noopener noreferrer" style={{ width: '100%', marginBottom: '5vw', marginTop: -5 }}/>
          </div>
          <div style={{ position: 'absolute', width: '100%', color: 'white', top: '15vw', textAlign: 'center' }}>
            {/*<div style={{ fontSize: '15vw', fontFamily: "Playfair Display" }}>Politweets</div>*/}
            <div style={{ fontSize: '7vw', fontFamily: "Playfair Display", width: '100%', marginBottom: '5vh' }}>Comment les présidents de partis belges utilisent-ils Twitter ?</div>
            <h5 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/AmCaW" target="_blank" rel="noopener noreferrer" alt="Carton">Ambroise Carton</a></strong> – Journaliste @RTBF</h5>
            <h5 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/karim_douieb" target="_blank" rel="noopener noreferrer" alt="Douieb">Karim Douieb</a></strong> – Data Visualisation <a href="https://jetpack.ai" style={{color: 'white'}} target="_blank" rel="noopener noreferrer" alt="jetpack">@Jetpack.AI</a></h5>
            <h5 style={{color: 'white'}}><strong><a style={{color: 'white'}} href="https://twitter.com/devironl" target="_blank" rel="noopener noreferrer" alt="Viron">Louis de Viron</a></strong> – Linguiste et Data Scientist <a href="https://datatext.eu" style={{color: 'white'}} target="_blank" rel="noopener noreferrer" alt="datatext">@DataText</a></h5>
          </div>
        </div>
      }
      <Row type="flex" justify="end"><div style={{marginRight: 30}}><Share /></div></Row>
      <Row type="flex" >
        <Col span={width >= 900 ? 20 : 24} offset={width >= 900 ? 2 : 0}>
          <Intro />
          <PresidentsScatter />
          <BadgeTop />
          <TweetThemesGlobal />
          <div style={{ paddingLeft: width >= 900 ? '5vh' : 16, paddingRight: width >= 900 ? '5vh' : 16, marginBottom: '5vh' }}>
            <strong>Méthodologie</strong>: Les tweets collectés ont été classifiés automatiquement à l’aide d’un outil d’intelligence artificielle. Chaque message est associé à la catégorie dont le vocabulaire est le plus proche du sien. Cette proximité est calculée à l’aide d’un <a href="https://radimrehurek.com/gensim/models/word2vec.html" target="_blank" rel="noopener noreferrer" alt="algorithme">algorithme développé par Google</a>.
          </div>
          <TweetThemesDetails />
          <BadgeList />
        </Col>
      </Row>
      <Row type="flex" justify="center" style={{marginBottom: 50}}><div ><Share /></div></Row>
      <Row type="flex" justify="space-around" align="middle" style={{ width: '50%', margin: 'auto', marginBottom: 50 }}>
        <a href="https://rtbf.be/info" target="_blank" rel="noopener noreferrer" alt="jetpack"><img alt="rtbf" src={rtbf} style={{ height: 40, marginBottom: 60 }}/></a>
        <a href="https://jetpack.ai" target="_blank" rel="noopener noreferrer" alt="jetpack"><img alt="jetpack" src={jetpack} style={{ height: 35, marginBottom: 60 }}/></a>
        <a href="https://datatext.eu" target="_blank" rel="noopener noreferrer" alt="datatext"><img alt="datatext" src={datatext} style={{ height: 35, marginBottom: 60 }}/></a>
      </Row>
    </div>
  );
}

export default MainLayout;
