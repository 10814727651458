import React, { useState, useEffect, useRef } from "react"
import { useWindowSize } from '@react-hook/window-size'
import ThemeChartOld from '../charts/ThemeChartOld/ThemeChartComponent'
import './TweetThemes.css'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function TweetThemesChart({ title, text, themeData, marginBottom}) {
  const [width] = useWindowSize();
  const [show, setShow] = useState(false)
  const sectionRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(sectionRef.current, {
    }, {
      duration: 0, 
      ease: 'none',
      scrollTrigger: {
        id: title.split(' ').join('') + '_theme',
        trigger: sectionRef.current,
        start: 'top 55%',
        end: 'top 25%',
        toggleActions: 'play none none reverse',
        onEnter: () => setShow(true),
        //onLeave: () => setIsActive(false),
        //onEnterBack: () => setActiveSection('step1'),
        //onLeaveBack: () => setIsActive(false),
        //onUpdate: (self) => setSectionProgress(id, self.progress),
        //markers: true,
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginBottom: 40 }} ref={sectionRef}>
      <div style={{ padding: width < 900 ? 16 : 64}}> 
        <h2>{title}</h2>
        <div style={{ marginBottom: marginBottom }}>{text}</div>
        <ThemeChartOld
          height={240} 
          referenceTheme={themeData} 
          currentTheme={null} 
          max={null} 
          selectPolitician={(p) => {}}
          show={show}
        />
      </div>
    </div>
  );
}

export default TweetThemesChart;
