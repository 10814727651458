/* eslint-disable eqeqeq */
import React, { useState } from "react"
import { Row, Col } from 'antd'
import { useWindowWidth } from '@react-hook/window-size'
import * as d3 from 'd3'
import _ from 'lodash';

import BadgeChart from '../charts/BadgeChart/BadgeChartComponent'
import politiStats from '../../assets/data/politiStats.json'
import Section from './SectionHighlight'

function BadgeChartComponent() {
  const width = useWindowWidth({wait: 1});
  //const [selectedYear, setSelectedYear] = useState(null);
  //const [selectedPresident, setSelectedPresident] = useState(null);
  //const [presidentStats, setPresidentStats] = useState(null);
  const [presidents] = useState(politiStats);
  const maxCount = _.maxBy(politiStats, 'full.count').full.count
  const [dateRange] = useState(d3.extent(_(politiStats)
    .map('monthly')
    .flatMap()
    .map(m => new Date(m.date))
    .value())
  )
  const [sectionsProgress, setSectionsProgress] = useState({});
  const [highlight, setHighlight] = useState(null);

  const setSectionProgress = (id, progress) => {
    sectionsProgress[id] =  { progress };
    setSectionsProgress({...sectionsProgress});
    setHighlight(id)
  } 
  
  /*useEffect(() => {
    setMaxCount(Math.max(...politiStats.map(d => {
      if(selectedYear && selectedYear !== 'null') {
        const y = d.yearly.find(y => moment(y.date).year() == selectedYear);
        return y ? y.count : 0
      }
      return d.full.count
    })))
  }, [selectedYear])*/

  /*useEffect(() => {
    if(selectedPresident) {
      const stats = selectedYear ? selectedPresident.yearly.find(y => moment(y.date).year() == selectedYear) : selectedPresident.full;
      setPresidentStats(stats)
    } else {
      setPresidentStats(null)
    }
  }, [selectedYear, selectedPresident])*/

  return (
    <div style={{ marginBottom: 40 }}>
      {/*<Tabs defaultValue={selectedYear} onChange={setSelectedYear}>
        <Tabs.TabPane tab="Last 5 years" key={null} />
        <Tabs.TabPane tab="2015" key="2015" />
        <Tabs.TabPane tab="2016" key="2016" />
        <Tabs.TabPane tab="2017" key="2017" />
        <Tabs.TabPane tab="2018" key="2018" />
        <Tabs.TabPane tab="2019" key="2019" />
        <Tabs.TabPane tab="2020" key="2020" />
      </Tabs>*/}

      <div>
        <div style={{ marginBottom: 40, position: 'relative' }}>
          { width <= 900 && <div style={{ padding: width >= 900 ? 0 : 16, marginBottom: width >= 900 ? '15vh' : 0 }}>
              <h1>Stratégies opposées</h1>
              <p>Georges-Louis Bouchez est donc le président le plus actif sur Twitter, tandis que Bart De Wever est le plus relayé.</p> 
            </div>
          } 
          <div className="graphic">
            { width >= 900 && <div style={{ padding: width >= 900 ? 0 : 16, marginBottom: width >= 900 ? '15vh' : 0 }}>
                <h1>Stratégies opposées</h1>
                <p>Georges-Louis Bouchez est donc le président le plus actif sur Twitter, tandis que Bart De Wever est le plus relayé.</p> 
              </div>
            }
            <Row type="flex" gutter={width >= 900 ? 16 : 0} >
              <Col span={ width >= 900 ? 10 : 24} offset={ width >= 900 ? 1 : 0}>
                <h5 style={{ marginLeft: 16 }}>LE PLUS ACTIF</h5>
                <h3 style={{ marginBottom: width < 900 ? 55 : 88, marginLeft: 16 }}>George-Louis Bouchez</h3>
                <BadgeChart 
                  data={presidents.find(p => p.name === 'GLBouchez')} 
                  maxCount={maxCount} 
                  dateRange={dateRange} 
                  highlight={highlight}
                />
              </Col>
              <Col span={ width >= 900 ? 10 : 24} offset={width >= 900 ? 2 : 0}>
                <h5 style={{ marginLeft: 16 }}>LE PLUS POPULAIRE</h5>
                <h3 style={{ marginBottom: width < 900 ? 0 : 88, marginLeft: 16 }}>Bart De Wever</h3>
                <BadgeChart 
                  data={presidents.find(p => p.name === 'Bart_DeWever')} 
                  maxCount={maxCount} 
                  dateRange={dateRange} 
                  highlight={highlight}
                />
              </Col>
            </Row>
          </div>
          <Row type="flex" justify="space-between">
            <Col span={width < 900 ? 20 : 12} offset={width < 900 ? 2 : 6}>
              <div style={{ zIndex: 1000, position: 'relative' }}>
                <Section id='tweets' setSectionProgress={setSectionProgress} >
                  <h3>Nombre de Tweets</h3>
                  <p>L’activité de Bart De Wever sur Twitter commence en 2014. Depuis, le président de la N-VA tweete peu, préférant les messages calibrés - <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Bart_DeWever/status/469070925393321984">parfois rédigés à la troisième personne</a> -  aux sorties spontanées.</p>
                </Section>
                <Section id='commentaires' setSectionProgress={setSectionProgress} >
                  <h3>Nombre de réponses</h3>
                  <p>Georges-Louis Bouchez réagit à chaud, sur tous les sujets, que son interlocuteur soit élu, journaliste ou citoyen. Une stratégie totalement à l’opposé de celle de Bart De Wever, qui ne répond presque jamais aux tweets qui lui sont adressés.</p>
                </Section>
                <Section id='hours' setSectionProgress={setSectionProgress} >
                  <h3>Moment de la journée</h3>
                  <p>Quand Georges-Louis Bouchez dort-il ? Probablement entre minuit et 7h du matin. En tout cas, en dehors de ces heures-là il est presque en permanence sur Twitter. En moyenne, le président du MR poste un tweet toutes les trois heures depuis trois ans, week-ends et vacances compris.</p>
                </Section>
                <Section id='likes' setSectionProgress={setSectionProgress} >
                  <h3>Nombre moyen de likes par tweet</h3>
                  <p>Le nombre de likes et de retweets sont de bons indicateurs de la popularité d’un tweet. Bart De Wever reçoit en moyenne 18 fois plus de likes que Georges-Louis Bouchez. </p>            
                </Section>
                <Section id='followers' setSectionProgress={setSectionProgress} style={{ marginBottom: '70vh' }} >
                  <h3>Nombre de followers</h3>
                  <p>Bart De Wever est présent sur la scène politique nationale depuis de longues années. Georges-Louis Bouchez n’y est arrivé que beaucoup plus tard. Cette différence se traduit dans leur nombre de followers. Le président de la N-VA est suivi par plus de 156.000 personnes sur Twitter, 9 fois plus que le libéral francophone.</p>
                </Section>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="site-drawer-render-in-current-wrapper">
        {/*<Drawer
          width="30vw"
          title="Top tweets"
          placement="right"
          closable={true}
          onClose={() => setSelectedPresident(null)}
          visible={presidentStats}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          { (selectedPresident && presidentStats) && 
            <div style={{ textAlign: 'left' }}>
              Les tweets de {selectedPresident.name} ayant eu le plus de succès au cours {selectedYear ? `de l'année ${selectedYear}` : 'des 3 dernières années'}.
              { _([...presidentStats.topLiked, ...presidentStats.topRetweeted, ...presidentStats.topReplyTo]).map('id').uniq().value().map(tweetId => 
                <div key={tweetId}>
                  <TweetEmbed id={tweetId} />
                </div>  
              )}
            </div>
          }
        </Drawer>*/}
        {/*<Row type="flex" justify="start">
          { presidents && _(presidents)
              .orderBy(d => d.full.count + d.full.responsesCount, 'desc')
              .filter(d => !['GLBouchez', 'Bart_DeWever'].includes(d.name))
              .value()
              .map(president => (
                <Col key={president.name} span={width < 900 ? 24 : 8} style={{ cursor: 'pointer' }}>
                  <h2 style={{width: '100%'}}>{presidentInfo[president.name].name} <small>({presidentInfo[president.name].party})</small></h2>
                  <BadgeChart 
                    data={president} 
                    maxCount={maxCount} 
                    dateRange={dateRange} 
                    year={null}//{selectedYear === 'null' ? null : selectedYear}
                  />
                </Col>
              )
            )
          }
        </Row>*/}
      </div>
    </div>
  );
}

export default BadgeChartComponent;
