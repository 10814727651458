/* eslint-disable eqeqeq */
import * as d3 from 'd3'
import _ from 'lodash'
import Chart from '../chart'
import moment from 'moment'
import textures from 'textures'

import './BadgeChart.css'

const heartPath = "M7.655 14.916L8 14.25l.345.666a.752.752 0 01-.69 0zm0 0L8 14.25l.345.666.002-.001.006-.003.018-.01a7.643 7.643 0 00.31-.17 22.08 22.08 0 003.433-2.414C13.956 10.731 16 8.35 16 5.5 16 2.836 13.914 1 11.75 1 10.203 1 8.847 1.802 8 3.02 7.153 1.802 5.797 1 4.25 1 2.086 1 0 2.836 0 5.5c0 2.85 2.045 5.231 3.885 6.818a22.075 22.075 0 003.744 2.584l.018.01.006.003h.002z"

export const presidentInfo = {
  "GLBouchez": {
    photo: "bouchez.jpg",
    name: "Georges-Louis Bouchez",
    party: "MR",
    followers: 17700,
  },
  "PaulMagnette": {
    photo: "magnette.jpg",
    name: "Paul Magnette",
    party: "PS",
    followers: 87400,
  },
  "francoisdesmet": {
    photo: "deSmet.jpg",
    name: "François De Smet",
    party: "DéFI",
    followers: 4981,
  },
  "RaoulHedebouw": {
    photo: "hedebouw.jpg",
    name: "Raoul Hedebouw",
    party: "PTB",
    followers: 20100,
  },
  "tomvangrieken": {
    photo: "vanGrieken.jpg",
    name: "Tom Van Grieken",
    party: "Vlaams Belang",
    followers: 28900,
  },
  "egbertlachaert": {
    photo: "lachaert.jpg",
    name: "Egbert Lachaert",
    party: "Open VLD",
    followers: 14900,
  },
  "Bart_DeWever": {
    photo: "deWever.jpg",
    name: "Bart De Wever",
    party: "N-VA",
    followers: 156200,
  },
  "conner_rousseau": {
    photo: "rousseau.jpg",
    name: "Conner Rousseau",
    party: "SP.A",
    followers: 19600,
  },
  "joachimcoens": {
    photo: "coens.jpg",
    name: "Joachim Coens",
    party: "CD&V",
    followers: 9279,
  },
  "peter_mertens": {
    photo: "mertens.jpg",
    name: "Peter Mertens",
    party: "PVDA",
    followers: 18300,
  },
  "RajaeMaouane": {
    photo: "maouane.jpg",
    name: "Rajae Maouane",
    party: "Ecolo",
    followers: 4406,
  },
  "jmnollet": {
    photo: "nollet.jpg",
    name: "Jean-Marc Nollet",
    party: "Ecolo",
    followers: 14500,
  },
  "prevotmaxime": {
    photo: "prevot.jpg",
    name: "Maxime Prévot",
    party: "cdH",
    followers: 7005,
  },
  "MeyremAlmaci": {
    photo: "almaci.jpg",
    name: "Meyrem Almaci",
    party: "Groen",
    followers: 25600,
  },
}

export default class BadgeChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.svg.select(function() { return this.parentNode; }).style('overflow', 'visible')

    this.main = this.svg.append('g')
      .attr('class', 'main');

    this.defs = this.svg.append('defs')
      .append('clipPath')

    this.likes = this.main.append('g')
      .attr('class', 'likes')

    this.bubbles = this.main.append('g')
      .attr('class', 'bubbles')

    this.followerCountBubble = this.main.append('g')
    this.followerCountBubbleCircle = this.followerCountBubble.append('circle')
    this.followerCountBubbleText = this.followerCountBubble.append('text')
    this.tweetCountBubble = this.main.append('g') 

    this.photo = this.main.append("image")

    this.hourChartLegend = this.main.append('g')
      .attr('class', 'hour-legend')
      .attr("transform", `translate(${this.props.width / 2}, ${this.props.width / 2})`)
      .attr('opacity', 0)

    this.hourChart = this.main.append('g')
      .attr('class', 'hour')
      .attr("transform", `translate(${this.props.width / 2}, ${this.props.width / 2})`)
  
    this.replies = this.main.append('g')
      .attr('class', 'replies')

    this.evolution = this.main.append('g')
      .attr('class', 'evolution')

    this.photoBorderWidth = this.props.width / 150
    this.innerRadius = this.props.width * 0.2
    this.outerRadius = this.props.width * 0.3
    this.photoSize = this.innerRadius * 2

    this.lineTexture = textures.lines()
      .size(6)
      .strokeWidth(1)
      .stroke("black");

    this.svg.call(this.lineTexture);

    this.defs
      .append('circle')
      .attr('r', this.photoSize / 2)
      .attr("cx", this.photoSize / 2)
      .attr("cy", this.photoSize / 2)
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.draw(state)
  }

  draw(state) {
    this.drawPhoto(state.data);
    this.drawBubbles(state.data, state.maxCount);
    this.drawFollowers(state.data)
    this.drawTweets(state.data, state.maxCount, state.year)
    this.drawHours(state.data, state.year)
    this.drawLikes(state.data, state.year)
    this.drawEvolution(state.data, state.dateRange)
    this.highlight(state.highlight)
  }

  drawPhoto (president) {
    const { name } = president;
    const photo = require(`../../../assets/images/presidents/${presidentInfo[name].photo}`);
    
    const randId = name + '-clip-' + Math.random();
    this.defs
      .attr('id', randId)
    this.photo
      .attr('clip-path', d => `url(#${randId})`)
      .attr("xlink:href", photo)
      .attr("width", this.photoSize)
      .attr("transform", `translate(${(this.props.width - this.photoSize) / 2}, ${(this.props.width - this.photoSize) / 2})`)
  }

  drawHours (president, year) {
    const stats = year ? president.yearly.find(y => moment(y.date).year() == year) : president.full
    let favoriteHour = { value: 0 };
    const hourlyDistribution = d3.range(24 * 4)
      .map(q => q * 15)
      .map(time => {
        if(stats && stats.hourlyDistribution[time] && stats.hourlyDistribution[time] > favoriteHour.value) {
          favoriteHour = { time, value: stats.hourlyDistribution[time] };
        }
        return {
          time,
          value: stats ? stats.hourlyDistribution[time] || 0 : 0
        }
      })
    const responseHourlyDistribution = d3.range(24 * 4)
      .map(q => q * 15)
      .map(time => ({
        time,
        value: stats ? stats.responseHourlyDistribution[time] || 0 : 0
      }))
    
    const x = d3.scaleLinear()
      .domain([0, 24 * 60])
      .range([0, 2 * Math.PI])
    const y = d3.scaleLinear()
      .domain([0, d3.max(hourlyDistribution.concat(responseHourlyDistribution), d => d.value)])
      .range([this.innerRadius, this.outerRadius])
    
    const area = d3.areaRadial()
      .curve(d3.curveCardinalClosed)
      .angle(d => x(d.time))
      .innerRadius(this.innerRadius + (this.photoBorderWidth/2))
      .outerRadius(d => y(d.value) + (this.photoBorderWidth/2))
    
    const radialLine = d3.lineRadial()
      .curve(d3.curveCardinalClosed)
      .angle(d => x(d.time))
      .radius(d => y(d.value) + (this.photoBorderWidth/2))
    
    function radialCoordinate(d) {
      const angle = x(d.time);
      const r = y(d.value);
      const xCoord = r * Math.cos(angle - (Math.PI / 2));
      const yCoord = r * Math.sin(angle - (Math.PI / 2));
      return [xCoord,yCoord];
    }  

    if(this.hourChart.selectAll('.border').empty()){
      this.hourChart.append("circle")
        .attr('class', 'border')
        .attr("r", this.photoSize / 2)
        .attr("fill", "none")
        .attr("stroke-width", this.photoBorderWidth)
        .attr("stroke", "white")
    }

    const paths = [
      { curve: radialLine(hourlyDistribution), opacity: 1, fill: 'none', stroke: 'white', strokeWidth: 6},
      { curve: area(hourlyDistribution), opacity: 1, fill: '#cce8fc', stroke: ''},
      { curve: radialLine(hourlyDistribution), opacity: 1, fill: 'none', stroke: '#1da1f2', strokeWidth: 2},
      { curve: radialLine(responseHourlyDistribution), opacity: 1, fill: 'none', stroke: 'black'}
    ]

    this.hourChart.selectAll('path')
      .data(paths)
      .join(enter => enter.append('path')
          .attr("fill", d => d.fill)
          .attr("stroke", d => d.stroke)
          .attr("stroke-width", d => d.strokeWidth)
          .attr("d", d => d.curve),
        update => update  
          .transition()
          .attr("d", d => d.curve)
      )
    
    if(this.hourChart.select('.favPin').empty()){
      this.hourChart.append("circle")
        .attr('class', 'favPin circle')
      this.hourChart.append("line")
        .attr('class', 'favPin line')
      this.hourChart.append("text")
        .attr('class', 'favPin text-background')
      this.hourChart.append("text")
        .attr('class', 'favPin text')
    }

    this.hourChart.selectAll("circle")
      .attr("fill", "black")
      .attr('r', 3)
      .attr("stroke", "white")
      .attr("cx", radialCoordinate(favoriteHour)[0])
      .attr("cy", radialCoordinate(favoriteHour)[1]);
    
    this.hourChart.selectAll("line")
      .attr("stroke", "black")
      .attr("x1", radialCoordinate({...favoriteHour, value: 0})[0])
      .attr("y1", radialCoordinate({...favoriteHour, value: 0})[1])
      .attr("x2", radialCoordinate(favoriteHour)[0])
      .attr("y2", radialCoordinate(favoriteHour)[1]);

    this.hourChart.selectAll(".text-background")
      .attr("x", radialCoordinate(favoriteHour)[0] + (this.props.width * 0.03))
      .attr("y", radialCoordinate(favoriteHour)[1] + (this.props.width * 0.03))
      .attr('text-anchor', 'start')
      .attr('stroke', 'white')
      .attr('stroke-width', 3)
      .attr('fill', 'white')
      .attr('font-size', this.props.width * 0.05)
      .text(`${Math.floor(favoriteHour.time/60).toLocaleString(undefined, {minimumIntegerDigits: 2})}:${(favoriteHour.time%60).toLocaleString(undefined, {minimumIntegerDigits: 2})}`);
    
    this.hourChart.selectAll(".text")
      .attr("x", radialCoordinate(favoriteHour)[0] + (this.props.width * 0.03))
      .attr("y", radialCoordinate(favoriteHour)[1] + (this.props.width * 0.03))
      .attr('text-anchor', 'start')
      .attr('font-size', this.props.width * 0.05)
      .text(`${Math.floor(favoriteHour.time/60).toLocaleString(undefined, {minimumIntegerDigits: 2})}:${(favoriteHour.time%60).toLocaleString(undefined, {minimumIntegerDigits: 2})}`);
  
    this.hourChartLegend.selectAll('.tick')
      .data(d3.range(12).map(h => ({ hour: 2 * h, time: h * 2 * 4 * 15, value: y.domain()[1]})))
      .join(
        enter => enter.append('g')
          .attr('class', 'tick')
          .call(enter => enter.append('line')
            .attr("stroke", "grey")
            .attr("stroke-width", 2)
            .attr("x1", d => radialCoordinate({...d, value: y.domain()[1] * 0.8})[0])
            .attr("y1", d => radialCoordinate({...d, value: y.domain()[1] * 0.8})[1])
            .attr("x2", d => radialCoordinate({...d, value: y.domain()[1] * 1.1})[0])
            .attr("y2", d => radialCoordinate({...d, value: y.domain()[1] * 1.1})[1])
          )
          .call(enter => enter.append('text')
            .attr("fill", "grey")
            .attr("x", d => radialCoordinate({...d, value: y.domain()[1] * 1.5})[0])
            .attr("y", d => radialCoordinate({...d, value: y.domain()[1] * 1.5})[1])
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text(d => `${d.hour}h`)
          )
      )
  }

  drawBubbles(president, maxCount) {
    const followerCountScale = d3.scaleSqrt()
      .domain([0, d3.max(_.values(presidentInfo), d => d.followers)])
      .range([1, this.props.width * 0.2])

    const tweetCountScale = d3.scaleSqrt()
      .domain([0, maxCount])
      .range([1, this.props.width * 0.2])

    const bubbles = _([
      { 
        id: 'followers', 
        fill: '#08C6AB', 
        r: followerCountScale(presidentInfo[president.name].followers), 
        x: ((this.props.width / 2) + (this.innerRadius) * 1.3), 
        y: ((this.props.width / 2) - (this.innerRadius) / 3),
        stroke: 'none', 
        strokeWidth: 0, 
        strokeDasharray: 'none', 
        //opacity: 1
      },
      { 
        id: 'tweetCount',
        r: tweetCountScale(president.full ? president.full.count : 0), 
        x: (this.props.width / 2) + (this.innerRadius),
        y: (this.props.width / 2) - (this.innerRadius),
        fill: '#1da1f2', 
        stroke: 'none', 
        strokeWidth: 0,
        strokeDasharray: 'none', 
        //opacity: 1
      },
      { 
        id: 'responsesCount',
        r: tweetCountScale(president.full ? president.full.responsesCount : 0), 
        x: (this.props.width / 2) + (this.innerRadius) - (tweetCountScale(president.full ? president.full.count : 0) * 0.9),
        y: (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(president.full ? president.full.count : 0) * 0.7),
        fill: this.lineTexture.url(),
        stroke: 'none', 
        strokeWidth: 0, 
        strokeDasharray: 'none', 
        //opacity: 0.2
      },
      { 
        id: 'responsesCountStroke',
        r: tweetCountScale(president.full ? president.full.responsesCount : 0), 
        x: (this.props.width / 2) + (this.innerRadius) - (tweetCountScale(president.full ? president.full.count : 0) * 0.9),
        y: (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(president.full ? president.full.count : 0) * 0.7),
        fill: 'none',
        stroke: 'black', 
        strokeWidth: 1, 
        strokeDasharray: 'none', 
        //opacity: 1
      }
    ])
    .orderBy('r', 'desc')
    .value()

    this.bubbles.selectAll('.bubble')
      .data(bubbles)
      .join(
        enter => enter.append('circle')
          .attr('class', d => `bubble ${d.id}`)
          .attr('r', d => d.r)
          .attr("cx", d => d.x)
          .attr("cy", d => d.y)
          .attr('fill', d => d.fill)
          .attr('stroke', d => d.stroke)
          .attr('stroke-width', d => d.strokeWidth)
          .style('stroke-dasharray', d => d.strokeDasharray)
          .style('opacity', d => d.opacity),
        update => update
          .attr('r', d => d.r)
          .attr("cx", d => d.x)
          .attr("cy", d => d.y)
          .attr('fill', d => d.fill)
          .attr('stroke', d => d.stroke)
          .attr('stroke-width', d => d.strokeWidth)
          .style('stroke-dasharray', d => d.strokeDasharray)
          .style('opacity', d => d.opacity),
      )
  }

  drawFollowers(president) {
    const followerCountScale = d3.scaleSqrt()
      .domain([0, d3.max(_.values(presidentInfo), d => d.followers)])
      .range([1, this.props.width * 0.2])

    /*this.followerCountBubbleCircle
      .attr('r', followerCountScale(presidentInfo[president.name].followers))
      .attr('fill', '#08C6AB')
      .attr("cx", (this.props.width / 2) + (this.innerRadius) * 1.3)
      .attr("cy", (this.props.width / 2) - (this.innerRadius) / 3)*/
    
    this.followerCountBubbleText
      .attr('class', 'text')
      .attr("x", (this.props.width / 2) + (this.innerRadius) * 1.2)
      .attr("y", (this.props.width / 2)  + (followerCountScale(presidentInfo[president.name].followers)) )
      .attr('fill', '#08C6AB')
      .attr('font-size', this.props.width * 0.05)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .text(`${d3.format(",")(presidentInfo[president.name].followers)} Followers`)
  }
 
  drawTweets(president, maxCount, year) {
    const tweetCountScale = d3.scaleSqrt()
      .domain([0, maxCount])
      .range([1, this.props.width * 0.2])

    const stats = year ? president.yearly.find(y => moment(y.date).year() == year) : president.full

    const texts = [
      `${d3.format(",")(stats ? stats.count : 0)} Tweets`,
      `${d3.format(",")(stats ? stats.responsesCount : 0)} Réponses`,
      //`${d3.format(".0%")(stats ? stats.noLoveCount/stats.count : 0)} sans ♡ | ${d3.format(".0%")(stats ? stats.unnoticedCount/stats.count : 0)} inaperçu`
    ]

    this.tweetCountBubble.selectAll('g')
      .data(texts)
      .join(
        enter => enter.append('g')
          .call(enter => enter.append("text")
            .attr('class', (d, i) => `background-label-${i}`)
            .attr("text-anchor", "middle")	
            .style("font-size", this.props.width * 0.05) 
            .attr("x", (this.props.width / 2) + (this.innerRadius))
            .attr("y", (d, i) => (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(stats ? stats.count : 0) + this.props.width * (i === 0 ? 0.08 : 0.02)))
            .attr('stroke', 'white')
            .attr('stroke-width', 4)
            .text(d => d)
          )
          .call(enter => enter.append("text")
            .attr('class', (d, i) => `label-${i}`)
            .attr("x", (this.props.width / 2) + (this.innerRadius))
            .attr("y", (d, i) => (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(stats ? stats.count : 0) + this.props.width * (i === 0 ? 0.08 : 0.02)))
            .attr("text-anchor", "middle")	
            .style("font-size", this.props.width * 0.05) 	
            .attr('font-weight', (d, i) => i === 0 ? 'bold' : '')
            .attr('fill', (d, i) => i === 0 ? '#1da1f2' : 'black')
            .text(d => d)
          ),
        /*update => update
          .call(update => update.select(".background-label")
            .attr("text-anchor", "middle")	
            .style("font-size", this.props.width * 0.05) 
            .attr("x", (this.props.width / 2) + (this.innerRadius))
            .attr("y", (d, i) => (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(stats ? stats.count : 0) + this.props.width * (i === 0 ? 0.07 : 0.02)))
            .attr('stroke', 'white')
            .attr('stroke-width', 4)
            .text(d => d)
          )
          .call(update => update.select(".label")
            .attr("x", (this.props.width / 2) + (this.innerRadius))
            .attr("y", (d, i) => (this.props.width / 2) - (this.innerRadius) - (tweetCountScale(stats ? stats.count : 0) + this.props.width * (i === 0 ? 0.07 : 0.02)))
            .attr("text-anchor", "middle")	
            .style("font-size", this.props.width * 0.05) 	
            .attr('font-weight', (d, i) => i === 0 ? 'bold' : '')
            .attr('fill', '#1da1f2')
            .text(d => d)
          ),*/
      )
  }

  drawLikes(president, year) {
    const stats = year ? president.yearly.find(y => moment(y.date).year() == year) : president.full
    const theta = Math.PI * (3 - Math.sqrt(5));
    const likePos =  d3.range(Math.max(1,Math.round(stats ? Math.round(stats.likeAverage/10) : 0)))
      .map((_, i) => {
        const radius = (this.props.width * 0.02) * Math.sqrt(i);
        const angle = i * theta;
        return { 
          x: radius * Math.cos(angle),
          y: radius * Math.sin(angle)
        }
      })
    
    this.likes.selectAll('.like')
      .data(likePos)
      .join(
        enter => enter.append('path')
          .attr('class', 'like')
          .attr('d', heartPath) 
          .attr('transform', d => `translate(${(this.props.width*0.15) + d.x},${(this.props.width*0.65) + d.y}) scale(${this.props.width / 600})`)
          .style('fill', '#e0255e')
          .style('opacity', 0),
        update => update
          .transition() 
          .delay((_, i) => i * 2)
          .style('opacity', 1),
        exit => exit
          .remove()
      )
      .transition() 
      .delay((_, i) => i * 2)
      .style('opacity', 1)
    
    const likeScale = d3.scaleSqrt()
      .range([0, 60])
      .domain([0, 100])
    
    const texts = [
      `${d3.format(",")(Math.round(stats ? stats.likeAverage : 0))} Likes par Tweet`
    ]

    this.likes.selectAll('text')
      .data(texts)
      .join(
        enter => enter.append('text')
          .attr('transform', d => `translate(${(this.props.width*0.15)},${(this.props.width*0.65)})`)
          .attr("x", likeScale(stats ? this.props.width * 0.1 : 0))
          .attr("y", likeScale(stats ? stats.likeAverage / 10 : 0)+ this.props.width*0.1)
          .attr('fill', '#e0255e')
          .attr('font-size', this.props.width * 0.05)
          .attr('font-weight', 'bold')
          .attr('text-anchor', 'middle')
          .text(d => d),
        update => update
          .transition() 
          .attr("x", likeScale(stats ? this.props.width * 0.1 : 0))
          .attr("y", likeScale(stats ? stats.likeAverage / 10 : 0)+ this.props.width*0.1)
          .text(d => d),
      )
  }

  drawEvolution(president, dateRange) {
    if (this.evolution.select('.x-axis').empty()) {
      const monthlyTweets = _(president.monthly)
        .map(m => ({
          date: new Date(m.date),
          value: m.count
        }))
        .orderBy('date')
        .value()
      
      const monthlyResponses = _(president.monthly)
        .map(m => ({
          date: new Date(m.date),
          value: m.responsesCount
        }))
        .orderBy('date')
        .value()

      const margin = {top: 20, right: 40, bottom: 30, left: 0}
      const evolutionWidth = this.props.width * 0.5;//width - this.props.width - margin.right;
      const evolutionHeight = this.props.width * 0.2;
      this.evolution.attr("transform", `translate(${(this.props.width * 0.9) + margin.left}, ${margin.top})`)
      const xEvolution = d3.scaleTime()
        .domain(dateRange)
        .range([0, evolutionWidth]).nice()
      const yEvolution = d3.scaleLinear()
        .domain([0, d3.max(monthlyTweets.concat(monthlyResponses), d => d.value)]).nice()
        .range([evolutionHeight, 0])
      const xAxis = d3.axisBottom(xEvolution)
        .ticks(3)
        .tickSizeOuter(0)
      const yAxis = d3.axisRight(yEvolution)
        .ticks(4)
        .tickSizeOuter(0)
      
      this.evolution.append("g")
        .attr("transform", `translate(0,${evolutionHeight})`)
        .attr("class", "x-axis")
        .call(xAxis)

      this.evolution.append("g")
        .attr("transform", `translate(${evolutionWidth},0)`)
        .attr("class", "y-axis")
        .call(yAxis)
        .call(g => g.select(".domain").remove())
      
      const line = d3.line()
        .defined(d => !isNaN(d.value))
        .x(d => xEvolution(d.date))
        .y(d => yEvolution(d.value))
        .curve(d3.curveMonotoneX)
      
      const areaEvolution = d3.area()
        .defined(d => !isNaN(d.value))
        .x(d => xEvolution(d.date))
        .y0(yEvolution(0))
        .y1(d => yEvolution(d.value))
        .curve(d3.curveMonotoneX)
      
      this.evolution.append("path")
        .attr('class', 'comments')
        .datum(monthlyResponses)
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", this.props.width/300)
        .attr("d", line)
      
      this.evolution.append("path")
        .attr('class', 'tweets')
        .datum(monthlyTweets)
        .attr("fill", "#1da1f2")
        .style("opacity", 0.05)
        .attr("d", areaEvolution)
      
      this.evolution.append("path")
        .attr('class', 'tweets')
        .datum(monthlyTweets)
        .attr("fill", "none")
        .attr("stroke", "#1da1f2")
        .attr("stroke-width", this.props.width/200)
        .attr("d", line)
    }
  }

  highlight(type) {
    this.bubbles.select('.tweetCount').attr('opacity', !type || type === 'tweets' ? 1 : 0.1)
    this.tweetCountBubble.select('.background-label-0').attr('opacity', !type || type === 'tweets' ? 1 : 0.1)
    this.tweetCountBubble.select('.label-0').attr('opacity', !type || type === 'tweets' ? 1 : 0.1)

    this.tweetCountBubble.select('.background-label-1').attr('opacity', !type || type === 'commentaires' ? 1 : 0.1)
    this.tweetCountBubble.select('.label-1').attr('opacity', !type || type === 'commentaires' ? 1 : 0.1)
    this.bubbles.select('.responsesCount').attr('opacity', !type || type === 'commentaires' ? 0.2 : 0)
    this.bubbles.select('.responsesCountStroke').attr('opacity', !type || type === 'commentaires' ? 1 : 0.1)
    this.replies.attr('opacity', !type || type === 'commentaires' ? 1 : 0.1)

    this.evolution.attr('opacity', !type || type === 'tweets' || type === 'commentaires' ? 1 : 0.1)
    this.evolution.select('.comments').attr('opacity', !type || type === 'commentaires' ? 1 : 0.1)
    this.evolution.selectAll('.tweets').attr('opacity', !type || type === 'tweets' ? 1 : 0.1)

    this.followerCountBubbleText.attr('opacity', !type || type === 'followers' ? 1 : 0.1)
    this.bubbles.select('.followers').attr('opacity', !type || type === 'followers' ? 1 : 0.1)

    this.likes.attr('opacity', !type || type === 'likes' ? 1 : 0.1)
    this.hourChart.attr('opacity', !type || type === 'hours' ? 1 : 0.1)
    this.hourChartLegend.attr('opacity', type === 'hours' ? 1 : 0)
  }
}