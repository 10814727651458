import React from "react";
import { IntlProvider } from 'react-intl';
import queryString from 'query-string';

import messagesEn from "./translations/en.json";
import messagesNl from "./translations/nl.json";
import messagesFr from "./translations/fr.json";

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/nl';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/nl'); 
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
}

const messages = {
  'nl': messagesNl,
  'en': messagesEn,
  'fr': messagesFr,
};

const language = navigator.language.split(/[-_]/)[0]; 
const supportedLanguage = ['nl', 'fr', 'en']
const browserLanguage = supportedLanguage.includes(language) ? language : null;

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    this.switchLanguage = (language) => {
      window.history.pushState(null, null, `?language=${language}`);
      this.setState({ locale: language, messages: messages[language] });
    }

    const parsed = queryString.parse(window.location.search);
    const urlLanguage = parsed && parsed.language && supportedLanguage.includes(parsed.language) ? parsed.language : null;
    const selectedLanguage = urlLanguage || browserLanguage || 'en';

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: selectedLanguage,
      messages: messages[selectedLanguage],
      switchLanguage: this.switchLanguage,
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };