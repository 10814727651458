import * as d3 from 'd3'
import _ from 'lodash'
import Chart from '../chart'

import './ThemeChart.css'

const presidentInfo = {
  "GLBouchez": {
    photo: "bouchez.jpg",
    name: "Georges-Louis Bouchez",
    lastName: "Bouchez",
    party: "MR",
    followers: 17700,
  },
  "PaulMagnette": {
    photo: "magnette.jpg",
    name: "Paul Magnette",
    lastName: "Magnette",
    party: "PS",
    followers: 87400,
  },
  "francoisdesmet": {
    photo: "deSmet.jpg",
    name: "François De Smet",
    lastName: "De Smet",
    party: "DéFI",
    followers: 4981,
  },
  "RaoulHedebouw": {
    photo: "hedebouw.jpg",
    name: "Raoul Hedebouw",
    lastName: "Hedebouw",
    party: "PTB",
    followers: 20100,
  },
  "tomvangrieken": {
    photo: "vanGrieken.jpg",
    name: "Tom Van Grieken",
    lastName: "Van Grieken",
    party: "Vlaams Belang",
    followers: 28900,
  },
  "egbertlachaert": {
    photo: "lachaert.jpg",
    name: "Egbert Lachaert",
    lastName: "Lachaert",
    party: "Open VLD",
    followers: 14900,
  },
  "Bart_DeWever": {
    photo: "deWever.jpg",
    name: "Bart De Wever",
    lastName: "De Wever",
    party: "N-VA",
    followers: 156200,
  },
  "conner_rousseau": {
    photo: "rousseau.jpg",
    name: "Conner Rousseau",
    lastName: "Rousseau",
    party: "SP.A",
    followers: 19600,
  },
  "joachimcoens": {
    photo: "coens.jpg",
    name: "Joachim Coens",
    lastName: "Coens",
    party: "CD&V",
    followers: 9279,
  },
  "peter_mertens": {
    photo: "mertens.jpg",
    name: "Peter Mertens",
    lastName: "Mertens",
    party: "PVDA",
    followers: 18300,
  },
  "RajaeMaouane": {
    photo: "maouane.jpg",
    name: "Rajae Maouane",
    lastName: "Maouane",
    party: "Ecolo",
    followers: 4406,
  },
  "jmnollet": {
    photo: "nollet.jpg",
    name: "Jean-Marc Nollet",
    lastName: "Nollet",
    party: "Ecolo",
    followers: 14500,
  },
  "prevotmaxime": {
    photo: "prevot.jpg",
    name: "Maxime Prévot",
    lastName: "Prévot",
    party: "cdH",
    followers: 7005,
  },
  "MeyremAlmaci": {
    photo: "almaci.jpg",
    name: "Meyrem Almaci",
    lastName: "Almaci",
    party: "Groen",
    followers: 25600,
  },
}

export default class ThemeChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.svg.select(function() { return this.parentNode; }).style('overflow', 'visible')

    this.main = this.svg.append('g')
      .attr('class', 'main themechart');

    this.label = this.main.append('g')
      .attr('class', 'label')

    this.defs = this.svg.append('defs')
      .append('clipPath')
      .attr('id', 'circle-clip')

    this.axis = this.main.append('g')
      .attr('class', 'axis');

    this.politiciansLink = this.main.append('g')
    .attr('class', 'politicians-link');

    this.politiciansRef = this.main.append('g')
      .attr('class', 'politicians-ref');

    this.politicians = this.main.append('g')
      .attr('class', 'politicians');

    this.averages = this.main.append('g')
      .attr('class', 'averages')

    this.averageSpan = this.averages.append('line')
      .attr('class', 'average-span')

    this.photoSize = Math.min(this.props.width * 0.1, this.props.height * 0.3)
    this.defs
      .append('circle')
      .attr('r', this.photoSize / 2)
      .attr("cx", this.photoSize / 2)
      .attr("cy", this.photoSize / 2)

    this.label.append('text')
      .attr('transform', `translate(${this.props.width}, ${this.props.height + 60}) `)
      .attr('font-size', 12)
      .attr('text-anchor', 'end')
      .attr('fill', 'grey')
      .text('Proportion des messages dans le thème')

    this.tooltip1 = this.main.append('g')

    this.tooltip1.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('stroke', 'white')
      .attr('stroke-width', 4)
      .attr('fill', 'none')
      .attr('opacity', 0)
      .attr('pointer-events', 'none')

    this.tooltip1.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('opacity', 0)
      .attr('pointer-events', 'none')

    this.tooltip2 = this.main.append('g')
    this.tooltip2.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('stroke', 'white')
      .attr('stroke-width', 4)
      .attr('fill', 'none')
      .attr('opacity', 0)
      .attr('pointer-events', 'none')

    this.tooltip2.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .attr('opacity', 0)
      .attr('pointer-events', 'none')
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.draw(state)
  }

  draw(state) {
    this.drawAxis(state);
    this.drawPolitics(state);
    this.drawAverage(state);
  }

  drawAxis (state) {
    const { referenceTheme } = state
    this.x = d3.scaleLinear()
      .domain([0, d3.max(referenceTheme.politicians, d => d.proportion)])
      .range([0, this.props.width]).nice()

    const xAxis = d3.axisBottom(this.x)
      .tickFormat(d3.format(".0%"))
      .ticks(8)

    this.axis
      .attr("transform", `translate(0,${this.props.height})`)
      .transition()
      .duration(2000)
      .call(xAxis)
  }

  drawPolitics (state) {
    const { referenceTheme, selectPolitician, show } = state
    const { politicians: referencePoliticians } = referenceTheme

    const referenceMapping = _(referencePoliticians)
      .keyBy('name')
      .value()

    const politicians = this.dodge(referenceTheme.politicians.map(pol => ({ 
      ...pol, 
      refCount: referenceMapping[pol.name].count, 
      refProportion: referenceMapping[pol.name].proportion 
    })), this.photoSize * 0.9)
    const photos = politicians.reduce((mapping, p) => {
      mapping[p.name] = require(`../../../assets/images/presidents/${presidentInfo[p.name].photo}`)
      return mapping
    }, {})

    this.politicians.selectAll('.politician')
      .data(politicians, d => d.name)
      .join(
        enter => enter.append('g')
          .attr('class', 'politician')
          .attr("transform", d => `translate(${-500}, ${(this.props.height * 0.8) - (this.photoSize / 2) - d.y})`)
          //.attr("transform", d => `translate(${d.x - (this.photoSize / 2) }, ${(this.props.height * 0.8) - (this.photoSize / 2) - d.y})`)
          .style('cursor', 'pointer')
          .call(enter => enter.append("image")
            .attr('clip-path', `url(#circle-clip)`)
            .attr("xlink:href", d => photos[d.name])
            .attr("width", this.photoSize)
          )
          .call(enter => enter.append("circle")
            .attr('fill', 'none')
            .attr("stroke", '#1da1f2')
            .attr("stroke-width", 2)
            .attr("cx", this.photoSize / 2)
            .attr("cy", this.photoSize / 2)
            .attr("r", this.photoSize / 2)
          ),
        update => update
          .transition()
          .duration(2000)
          .delay((d, i) => i * 50)
          .attr("transform", d => show ? `translate(${d.x - (this.photoSize / 2) }, ${(this.props.height * 0.8) - (this.photoSize / 2) - d.y})` : `translate(${-500}, ${(this.props.height * 0.8) - (this.photoSize / 2) - d.y})`)
          .call(update => update.select('circle')
            .attr("stroke", d => d.refProportion > d.proportion ? 'dodgerblue' : (d.refProportion === d.proportion ? '#1da1f2' : 'tomato'))
          ),
      )
      .on('click', d => selectPolitician(d))
      .on('mouseenter', d => {
        this.tooltip1.selectAll('text')
          .text(`${presidentInfo[d.name].lastName} (${presidentInfo[d.name].party})`)
          .attr('opacity', 1)
          .attr('x', d.x)
          .attr('y',(this.props.height * 0.8) -  d.y - (this.photoSize / 2) - 18)

        this.tooltip2.selectAll('text')
          .text(`${d.count} tweets`)
          .attr('opacity', 1)
          .attr('x', d.x)
          .attr('y',(this.props.height * 0.8) -  d.y - (this.photoSize / 2) - 4)
      })
      .on('mouseleave', d => {
        this.tooltip1.selectAll('text')
          .text('')
          .attr('opacity', 0)
        
        this.tooltip2.selectAll('text')
          .text('')
          .attr('opacity', 0)
      })

    this.politiciansRef.selectAll('.politician-ref')
      .data(politicians, d => d.name)
      .join(
        enter => enter.append('circle')
          .attr('class', 'politician-ref')
          .attr('fill', '#1da1f2')
          .attr("stroke", 'white')
          .attr("stroke-width", 2)
          .attr("cx", -500)
          .attr("cy", this.props.height)
          .attr("r", 4),
        update => update
          .transition()
          .duration(2000)
          .delay((d, i) => i * 50)
          .attr('fill', d => d.refProportion > d.proportion ? 'dodgerblue' : (d.refProportion === d.proportion ? '#1da1f2' : 'tomato'))
          .attr("cx", d => show ? this.x(d.refProportion) : -500)
      )

    this.politiciansLink.selectAll('.politician-link')
      .data(politicians, d => d.name)
      .join(
        enter => enter.append('path')
          .attr('class', d => `politician-link ${d.name}`)
          .attr('fill', 'none')
          .attr("stroke", d => d.refProportion > d.proportion ? 'dodgerblue' : (d.refProportion === d.proportion ? '#1da1f2' : 'tomato'))
          .attr("stroke-width", 2)
          .attr("d", d => {
            const dx = -500
            const dy = this.props.height - ((this.props.height * 0.8) - d.y)
            const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
            const dr = distance*1.5;
            return `M${-500},${this.props.height}A${dr},${dr} 0 0,1 ${-500},${((this.props.height * 0.8) - d.y)}`
          }),
        update => update
          .transition()
          .duration(2000)
          .delay((d, i) => i * 50)
          .attr("stroke", d => d.refProportion > d.proportion ? 'dodgerblue' : (d.refProportion === d.proportion ? '#1da1f2' : 'tomato'))
          .attr("d", d => {
            const dx = show ? this.x(d.refProportion) - d.x : -500
            const dy = this.props.height - ((this.props.height * 0.8) - d.y)
            const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
            const dr = distance*1.5;
            return `M${ show ? this.x(d.refProportion) : -500},${this.props.height}A${dr},${dr} 0 0,1 ${show ? d.x : -500},${((this.props.height * 0.8) - d.y)}`
            /*d.refProportion < d.proportion ? `M${this.x(d.refProportion)},${this.props.height}A${dr},${dr} 0 0,1 ${d.x},${((this.props.height * 0.8) - d.y)}`
              : `M${d.x},${((this.props.height * 0.8) - d.y)}A${dr},${dr} 0 0,1 ${this.x(d.refProportion)},${this.props.height}`*/
          }),
      )
  }

  dodge = (data, radius) => {
    const radius2 = radius ** 2;
    const circles = data.map(d => ({ ...d, x: this.x(d.proportion)})).sort((a, b) => a.x - b.x);
    const epsilon = 1e-3;
    let head = null, tail = null;
  
    // Returns true if circle ⟨x,y⟩ intersects with any circle in the queue.
    function intersects(x, y) {
      let a = head;
      while (a) {
        if (radius2 - epsilon > (a.x - x) ** 2 + (a.y - y) ** 2) {
          return true;
        }
        a = a.next;
      }
      return false;
    }
  
    // Place each circle sequentially.
    for (const b of circles) {
  
      // Remove circles from the queue that can’t intersect the new circle b.
      while (head && head.x < b.x - radius2) head = head.next;
  
      // Choose the minimum non-intersecting tangent.
      if (intersects(b.x, b.y = 0)) {
        let a = head;
        b.y = Infinity;
        do {
          let y = a.y + Math.sqrt(radius2 - (a.x - b.x) ** 2);
          if (y < b.y && !intersects(b.x, y)) b.y = y;
          a = a.next;
        } while (a);
      }
  
      // Add b to the queue.
      b.next = null;
      if (head === null) head = tail = b;
      else tail = tail.next = b;
    }
  
    return circles;
  }

  drawAverage(state) {
    const { referenceTheme } = state
    this.averages.selectAll('.average')
      .data([referenceTheme.averageProportion])
      .join(
        enter => enter.append('g')
          .attr('class', `average`)
          .call( enter => enter.append('rect')
            .attr('fill', 'grey')
            .attr('stroke', 'white')
            .attr("rx", 2)
            .attr("ry", 2)
            .attr('width', 4)
            .attr('height', this.props.margin.bottom)
            .attr('y', this.props.height - 8)
            .attr('x', d => this.x(d) - 2)
          )
          .call( enter => enter.append('text')
            .attr('fill', 'grey')
            .attr('y', this.props.height + 35)
            .attr('x', d => this.x(d))
            .attr('text-anchor', 'middle')
            .text(d => `Moyenne (${Math.round(d*1000)/10}%)`)
          ),
      )
  }
}


