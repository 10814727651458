/* eslint-disable eqeqeq */
import * as d3 from 'd3'
import _ from 'lodash'
import Chart from '../chart'
import moment from 'moment'

import './PresidentChart.css'

const heartPath = "M7.655 14.916L8 14.25l.345.666a.752.752 0 01-.69 0zm0 0L8 14.25l.345.666.002-.001.006-.003.018-.01a7.643 7.643 0 00.31-.17 22.08 22.08 0 003.433-2.414C13.956 10.731 16 8.35 16 5.5 16 2.836 13.914 1 11.75 1 10.203 1 8.847 1.802 8 3.02 7.153 1.802 5.797 1 4.25 1 2.086 1 0 2.836 0 5.5c0 2.85 2.045 5.231 3.885 6.818a22.075 22.075 0 003.744 2.584l.018.01.006.003h.002z"
const tweetPath = "M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578 9.3 9.3 0 0 1-2.958 1.13 4.66 4.66 0 0 0-7.938 4.25 13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568 4.692 4.692 0 0 1-2.104.08 4.661 4.661 0 0 0 4.352 3.234 9.348 9.348 0 0 1-5.786 1.995 9.5 9.5 0 0 1-1.112-.065 13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41z"
const responsePath = "M110,57.5 L456,57.5 C477.815248,57.5 495.5,75.1847524 495.5,97 L495.5,238 C495.5,259.815248 477.815248,277.5 456,277.5 L266,277.5 L265.910605,277.508055 C265.822645,277.52404 265.739998,277.563436 265.671738,277.622848 L158.5,370.9 L158.5,278 C158.5,277.723858 158.276142,277.5 158,277.5 L110,277.5 C88.1847524,277.5 70.5,259.815248 70.5,238 L70.5,97 C70.5,75.1847524 88.1847524,57.5 110,57.5 Z"

const presidentInfo = {
  "GLBouchez": {
    photo: "bouchez.jpg",
    name: "Georges-Louis Bouchez",
    lastName: "Bouchez",
    party: "MR",
    followers: 17700,
  },
  "PaulMagnette": {
    photo: "magnette.jpg",
    name: "Paul Magnette",
    lastName: "Magnette",
    party: "PS",
    followers: 87400,
  },
  "francoisdesmet": {
    photo: "deSmet.jpg",
    name: "François De Smet",
    lastName: "De Smet",
    party: "DéFI",
    followers: 4981,
  },
  "RaoulHedebouw": {
    photo: "hedebouw.jpg",
    name: "Raoul Hedebouw",
    lastName: "Hedebouw",
    party: "PTB",
    followers: 20100,
  },
  "tomvangrieken": {
    photo: "vanGrieken.jpg",
    name: "Tom Van Grieken",
    lastName: "Van Grieken",
    party: "Vlaams Belang",
    followers: 28900,
  },
  "egbertlachaert": {
    photo: "lachaert.jpg",
    name: "Egbert Lachaert",
    lastName: "Lachaert",
    party: "Open VLD",
    followers: 14900,
  },
  "Bart_DeWever": {
    photo: "deWever.jpg",
    name: "Bart De Wever",
    lastName: "De Wever",
    party: "N-VA",
    followers: 156200,
  },
  "conner_rousseau": {
    photo: "rousseau.jpg",
    name: "Conner Rousseau",
    lastName: "Rousseau",
    party: "SP.A",
    followers: 19600,
  },
  "joachimcoens": {
    photo: "coens.jpg",
    name: "Joachim Coens",
    lastName: "Coens",
    party: "CD&V",
    followers: 9279,
  },
  "peter_mertens": {
    photo: "mertens.jpg",
    name: "Peter Mertens",
    lastName: "Mertens",
    party: "PVDA",
    followers: 18300,
  },
  "RajaeMaouane": {
    photo: "maouane.jpg",
    name: "Rajae Maouane",
    lastName: "Maouane",
    party: "Ecolo",
    followers: 4406,
  },
  "jmnollet": {
    photo: "nollet.jpg",
    name: "Jean-Marc Nollet",
    lastName: "Nollet",
    party: "Ecolo",
    followers: 14500,
  },
  "prevotmaxime": {
    photo: "prevot.jpg",
    name: "Maxime Prévot",
    lastName: "Prévot",
    party: "cdH",
    followers: 7005,
  },
  "MeyremAlmaci": {
    photo: "almaci.jpg",
    name: "Meyrem Almaci",
    lastName: "Almaci",
    party: "Groen",
    followers: 25600,
  },
}

export default class PresidentChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.svg.select(function() { return this.parentNode; }).style('overflow', 'visible')

    this.main = this.svg.append('g')
      .attr('class', 'main president');

    this.defs = this.svg.append('defs')
      .append('clipPath')
      .attr('id', 'president-chart-circle-clip')
      
    this.popularityTranches = this.main.append('g')
      .attr('class', 'popularity-tranches');

    this.activityTranches = this.main.append('g')
      .attr('class', 'activity-tranches');

    this.icons = this.main.append('g')
      .attr('class', 'icons');

    this.xAxis = this.main.append('g')
      .attr('class', 'x axis');

    this.xAxisLabel = this.xAxis.append('text');

    this.yAxis = this.main.append('g')
      .attr('class', 'y axis');

    this.yAxisLabel = this.yAxis.append('text');

    this.politicianNames = this.main.append('g')
      .attr('class', 'politician-names');

    this.politicianPaths = this.main.append('g')
      .attr('class', 'politicianPaths');

    this.politicians = this.main.append('g')
      .attr('class', 'politicians');

    this.photoSize = Math.max(this.props.width * 0.05, this.props.height * 0.08)
    this.defs
      .append('circle')
      .attr('r', this.photoSize / 2)
      .attr("cx", this.photoSize / 2)
      .attr("cy", this.photoSize / 2)
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.draw(state)
  }

  draw(state) {
    this.drawAxis(state);
    this.drawPolitics(state);
    this.drawIcon(state);
  }

  drawAxis (state) {
    const { data, year, withComments, showActivity, showPopularity } = state
    const presidentStats = _(data)
      .map(president => {
        return {
          name: president.name,
          stats: year ? president.yearly.find(y => moment(y.date).year() == year) : president.full
        }
      })
      .value()

    this.x = d3.scaleLog()
      .domain([10, d3.max(presidentStats, d => d.stats && d.stats.likeAverage)])
      .range([0, this.props.width])
      .nice()

    this.y =  d3.scaleLinear()
      .domain([0, d3.max(presidentStats, d => d.stats && (d.stats.count + (withComments ? d.stats.responsesCount : 0)))])
      .range([this.props.height, 0]).nice()

    const xAxis = g => g
      .attr("transform", `translate(0,${this.props.height})`)
      .call(
        d3.axisBottom(this.x)
          .tickFormat(d3.format(",.1r"))
          .tickValues([10,20,40,60,80,100,500, 1000])
          .tickSize(-this.props.height)
      )
      .call(g => g.select(".domain").remove())

    const yAxis = g => g
      .attr("transform", `translate(0,0)`)
      .call(g => g.select(".domain").remove())
      .select(".tick:first-of-type text").remove()
      .select(".tick:first-of-type line").remove()

    this.yAxis
      .transition()
      .duration(2000)
      .call(
        d3.axisLeft(this.y)
          .tickSizeOuter(0)
          .tickSize(-this.props.width)
      )
      .selectAll(".tick text")
      .attr("y", 8)
      .attr("x", 6)
      .style("text-anchor", "start")

    this.xAxisLabel
      .attr("x", this.props.width - 4)
      .attr("y", 30)
      .attr("text-anchor", "end")
      .text("Nombre de Likes moyen par tweet")

    this.yAxisLabel
      .attr("x", 4)
      .attr("y", -10)
      .attr("text-anchor", "start")
      .text(`Nombre de tweets ${state.withComments ? 'et réponses ' : ''}publiés depuis 2018`)
      
    this.xAxis.call(xAxis);
    this.yAxis.call(yAxis)

    this.activityTranches.selectAll('.tranche')
      .data(['Prolifique', 'Modéré', 'Passif'])
      .join(
        enter => enter.append('g')
          .attr('class', 'tranche')
          .attr("transform", d => `translate(0, ${2*this.props.height/3})`)
          .attr("opacity", showActivity ? 1 : 0)
          .call(enter => enter.append("rect")
            .attr('fill', '#1da1f2')
            .attr('opacity', (d, i) => 1 / (i+1))
            .attr('width', this.props.width)
            .attr("height", this.props.height/3)
          )
          .call(enter => enter.append("text")
            .attr('fill', 'white')
            .attr("x", this.props.width - 20)
            .attr("y", 20)
            .attr("text-anchor", 'end')
            .text(d => d)
          ),
        update => update
          .attr("opacity", showActivity ? 1 : 0)
          .transition()
          .duration(1000)
          .attr("transform", (d, i) => `translate(0, ${ showActivity ? i * this.props.height/3 : 2*this.props.height/3})`),
      )
    
    this.popularityTranches.selectAll('.tranche')
      .data(['Peu populaire', 'Populaire', 'Très Populaire'])
      .join(
        enter => enter.append('g')
          .attr('class', 'tranche')
          .attr("transform", d => `translate(0, 0)`)
          .attr("opacity", showPopularity ? 1 : 0)
          .call(enter => enter.append("rect")
            .attr('fill', '#1da1f2')
            .attr('opacity', (d, i) => (i+1)/3)
            .attr('width', this.props.width/3)
            .attr("height", this.props.height)
          )
          .call(enter => enter.append("text")
            .attr('fill', 'white')
            .attr("x", (this.props.width/3) - 20)
            .attr("y", 20)
            .attr("text-anchor", 'end')
            .text(d => d)
          ),
        update => update
          .attr("opacity", showPopularity ? 1 : 0)
          .transition()
          .duration(1000)
          .attr("transform", (d, i) => `translate(${ showPopularity ? i * this.props.width/3 : 0}, 0)`),
      )
  }

  drawPolitics (state) {
    const { data, year, withComments } = state
    const presidentStats = _(data)
      .map(president => {
        return {
          name: president.name,
          stats: year ? president.yearly.find(y => moment(y.date).year() == year) : president.full
        }
      })
      .value()

    const photos = presidentStats.reduce((mapping, p) => {
        mapping[p.name] = require(`../../../assets/images/presidents/${presidentInfo[p.name].photo}`)
        return mapping
      }, {})

    this.politicians.selectAll('.politician')
      .data(presidentStats, d => d.name)
      .join(
        enter => enter.append('g')
          .attr('class', 'politician')
          .attr("transform", d => `translate(${d.stats ? this.x(d.stats && d.stats.likeAverage) - (this.photoSize / 2) : - (this.photoSize/2)}, ${d.stats ? this.y(d.stats.count + (withComments ? d.stats.responsesCount : 0)) - (this.photoSize / 2) : this.props.height})`)
          .call(enter => enter.append("image")
            .attr('clip-path', `url(#president-chart-circle-clip)`)
            .attr("xlink:href", d => photos[d.name])
            .attr("width", this.photoSize)
          )
          .call(enter => enter.append("circle")
            .attr('fill', 'none')
            .attr("stroke", 'white')
            .attr("stroke-width", 2)
            .attr("cx", this.photoSize / 2)
            .attr("cy", this.photoSize / 2)
            .attr("r", this.photoSize / 2)
          ),
        update => update
          .transition()
          .duration(2000)
          .attr("transform", d => `translate(${d.stats ? this.x(d.stats && d.stats.likeAverage) - (this.photoSize / 2) : - (this.photoSize/2)}, ${d.stats ? this.y(d.stats && (d.stats.count + (withComments ? d.stats.responsesCount : 0))) - (this.photoSize / 2) : this.props.height})`),
      )

    this.politicianNames.selectAll('.label')
      .data(presidentStats, d => d.name)
      .join(
        enter => enter.append('text')
          .attr('class', 'label')
          .attr('fill', 'black')
          .attr("x", d => d.stats ? this.x(d.stats && d.stats.likeAverage) : - (this.photoSize/2))
          .attr("y", d => d.stats ? this.y(d.stats.count + (withComments ? d.stats.responsesCount : 0)) - (this.photoSize / 2) - 8 : this.props.height)
          .attr("font-size", 10)
          .attr("text-anchor", 'middle')
          .text(d => presidentInfo[d.name].lastName)
        ,
        update => update
          .transition()
          .duration(2000)
          .attr("x", d => d.stats ? this.x(d.stats && d.stats.likeAverage) : - (this.photoSize/2))
          .attr("y", d => d.stats ? this.y(d.stats.count + (withComments ? d.stats.responsesCount : 0)) - (this.photoSize / 2) - 8 : this.props.height),
      )
  }

  drawIcon(state) {
    this.icons.selectAll('.likeIcon')
      .data([this.props.width / 3, (2 * this.props.width / 3) - 10, 2 * this.props.width / 3, this.props.width, this.props.width - 10, this.props.width - 20, this.props.width - 30, this.props.width - 40])
      .join( enter => enter
        .append('path')
        .attr('class', 'likeIcon')
        .attr('d', heartPath) 
        .attr('transform', d => `translate(${d - 10},${this.props.height + 10}) scale(${this.props.height * 0.0012})`)
        .style('fill', '#e0255e')
        .style('stroke', 'white')
        .style('stroke-width', 1)
      )

    this.icons.selectAll('.tweetIcon')
      .data([0, 12, 24, this.props.height / 3, this.props.height / 3 + 12, 2 * this.props.height / 3])
      .join( enter => enter
          .append('path')
          .attr('class', 'tweetIcon')
          .attr('d', tweetPath) 
          .attr('transform', d => `translate(${ state.smallScreen ? 8 : -20 },${d + 15}) scale(${ this.props.height * 0.0012})`)
          .style('fill', '#1da1f2')
          .style('stroke', 'white')
          .style('stroke-width', state.smallScreen ? 1 : 0.5),
        update => update
          .transition()
          .duration(1000)
          .delay((d, i) => i * 100)
          .attr('transform', d => `translate(${ state.smallScreen ? 8 : state.withComments ? -45 : -20 },${d + 15}) scale(${ this.props.height * 0.0012})`)
      )

    this.icons.selectAll('.responseIcon')
      .data([0, 14, 28, this.props.height / 3, this.props.height / 3 + 14, 2 * this.props.height / 3])
      .join( enter => enter
          .append('path')
          .attr('class', 'responseIcon')
          .attr('d', responsePath) 
          .attr('transform', d => `translate(${ -300 },${d + 15}) scale(${ this.props.height * 0.00006})`)
          .style('fill', 'white')
          .style('stroke', 'black')
          .style('stroke-width', 30),
        update => update
          .transition()
          .duration(1000)
          .delay((d, i) => i * 100)
          .attr('transform', d => `translate(${ state.smallScreen ? state.withComments ? 25 : -300 : state.withComments ? -25 : -300 },${d + 15}) scale(${ this.props.height * 0.00006})`)
      )
  }
}


