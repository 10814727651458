/* eslint-disable no-loop-func */
import * as d3 from 'd3'
import _ from 'lodash'
import Chart from '../chart'
import moment from 'moment'

import './BumpChart.css'

const themeLabels = {
  politique: "Politique",
  fiscalité_économie: "Fiscalité & économie",
  éducation: "Éducation",
  emploi_pensions: "Emploi & pensions",
  sécurité_justice: "Sécurité & justice",
  société: "Société",
  écologie: "Ecologie",
  coronavirus: "Coronavirus",
  santé: "Santé",
  mobilité: "Mobilité",
  immigration: "Immigration",
}

function CurveSankey(context) {
  this._context = context;
}

CurveSankey.prototype = {
  areaStart: function() {
    this._line = 0;
  },
  areaEnd: function() {
    this._line = NaN;
  },
  lineStart: function() {
    this._x = this._y = NaN;
    this._point = 0;
  },
  lineEnd: function() {
    if (this._line || (this._line !== 0 && this._point === 1)) this._context.closePath();
    this._line = 1 - this._line;
  },
  point: function(x, y) {
    x = +x;
    y = +y;
    switch (this._point) {
      case 0:
        this._point = 1;
        this._line ? this._context.lineTo(x, y) : this._context.moveTo(x, y);
        break;
      case 1:
        this._point = 2; // proceed
      // eslint-disable-next-line no-fallthrough
      default:
        var mx = (x - this._x) / 2 + this._x;
        this._context.bezierCurveTo(mx, this._y, mx, y, x, y);
        break;
    }
    this._x = x;
    this._y = y;
  }
};

const curveSankey = function(context) {
  return new CurveSankey(context);
}

export default class BumpChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    //this.svg.select(function() { return this.parentNode; }).style('overflow', 'visible')

    this.main = this.svg.append('g')
      .attr('transform', ` translate(${this.props.width},0) rotate(90)`)
      .attr('class', 'main bumpchart');

    this.axis = this.main.append('g')
      .attr('class', 'axis');

    this.layersView = this.main.append('g')
    .attr('class', 'layers-view');

    this.svg.append("svg:defs").append("svg:marker")
      .attr("id", "triangle")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 15)
      .attr("markerHeight", 15)
      .attr("markerUnits","userSpaceOnUse")
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 10 5 0 10 0 5")
      .style("fill", "grey");

    this.legend = this.main.append('g')
      .attr('class', 'legend');

    this.legend.append('line')
      .attr('x1', -8)
      .attr('x2', -8)
      .attr('y1', this.props.width * 0.49)
      .attr('y2', this.props.width * 0.2)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1)
      .attr("marker-end", "url(#triangle)")

    this.legend.append('text')
      .attr('transform', `translate(${-12}, ${this.props.width * 0.49}) rotate(-90) `)
      .attr('font-size', 10)
      .attr('fill', 'grey')
      .text('Plus fréquents')

    this.legend.append('line')
      .attr('x1', -8)
      .attr('x2', -8)
      .attr('y1', this.props.width * 0.51)
      .attr('y2', this.props.width * 0.8)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1)
      .attr("marker-end", "url(#triangle)")

    this.legend.append('text')
      .attr('transform', `translate(${-12}, ${this.props.width * 0.51}) rotate(-90) `)
      .attr('font-size', 10)
      .attr('text-anchor', 'end')
      .attr('fill', 'grey')
      .text('Moins fréquents')
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    this.draw(state)
  }

  draw(state) {
    this.drawAxis(state);
    this.drawChart(state);
  }

  drawAxis (state) {
    const { data, normalize, padding = 2 } = state

    const  stack = d3.stack()
      .keys(data.keys)
      .offset(normalize ? d3.stackOffsetExpand : d3.stackOffsetNone);

    this.layers = stack(data.values);

    this.x = d3.scaleTime()
      .domain(d3.extent(data.values, d => moment(d.date).toDate() ))
      .range([0, this.props.height]);

    this.y = d3.scaleLinear()
      .domain(d3.extent(_(this.layers).flatMap().flatMap().value()))
      .range([0, this.props.width - padding * (this.layers.length - 1)]);
    
    //sort layers by size
    for (var i = 0; i < this.layers[0].length; i++) {
      var values = this.layers.map(layer => layer[i])
        .sort((a, b) => (b[1] - b[0]) - (a[1] - a[0])); //descending
      var sum = d3.sum(values, layer => layer[1] - layer[0]);
      var y0 = (this.y.domain()[1] - sum) / 2;
      values.forEach((layer, li) => {
        var yd = layer[1] - layer[0];
        layer[0] = y0;
        layer[1] = y0 + yd;
        //increase y0
        y0 += yd + this.y.invert(padding);
      });
    }
    
    d3.timeFormatDefaultLocale({
      dateTime: "%A %e %B %Y à %X",
      date: "%d/%m/%Y",
      time: "%H:%M:%S",
      periods: ["AM","PM"],
      days: ["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"],
      shortDays: ["dim.","lun.","mar.","mer.","jeu.","ven.","sam."],
      months: ["janvier","février","mars","avril","mai","juin","juillet","août","septembre","octobre","novembre","décembre"],
      shortMonths: ["janv.","févr.","mars","avr.","mai","juin","juil.","août","sept.","oct.","nov.","déc."]
    })

    const xAxis = d3.axisTop(this.x)
      .tickSize(this.props.width);

    this.axis
      .attr("class", "x axis")
      .style("stroke-width", "1px")
      .style("font-size", "10px")
      //.style("font-family", "Arial, Helvetica")
      .attr("transform", "translate(" + 0 + "," + (this.props.width) + ")")
      .call(xAxis)
      .call(g => g.select(".domain").remove())
  }

  drawChart (state) {
    const { data } = state

    const color = d3.scaleOrdinal()
      .domain(data.keys)
      .range(["#ffc107", "#2196f3", "#f44336", "#673ab7", "#607d8b", "#e91e63", "#4caf50", "#9c27b0", "#795548", "#00bcd4", "#ff9800", "#3f51b5", "#cddc39", "#8bc34a", "#009688", "#ff5722", "#03a9f4", "#ffeb3b"])

    const area = d3.area()
      .curve(curveSankey)
      .x(d => this.x(moment(d.data.date).toDate()))
      .y0(d => this.y(d[0]))
      .y1(d => this.y(d[1]));
    
    const splitLayers = _(this.layers)
      .flatMap(layer => {
        const segments = d3.pairs(layer)
          .map((seg, i) => {
            seg.key = layer.key
            seg.id = layer.key + '-' + i
            seg.date = moment(seg[0].data.date)
            return seg
          })
        return segments
      })
      .orderBy([d => d[1].data.date, d => d[1][1]], ['asc', 'desc'])
      .value()
    
    this.layersView.selectAll("path.layer")
      .data(splitLayers, d => d.id)
      .join(
        enter => enter.append("path")
          .attr("class", "layer")
          .attr("fill", d => color(d.key))
          .attr("title", d => d.key)
          .style('opacity', 0.95)
          .attr("d", area),
        update => update
          .transition()
          .duration(300)
          .style('opacity', d => !state.highlight || ((!state.highlight.items || state.highlight.items.includes(d.key)) && (!state.highlight.dates || d.date.isBetween(...state.highlight.dates))) ? 0.95 : 0.2)
          .transition()
          .duration(1000)
          .attr("d", area),
      )

    this.layersView.selectAll(".layer-label")
      .data(this.layers)
      .join(
        enter => enter.append("text")
          .attr("class", "layer-label")
          .attr("x", this.props.height + 8)
          .attr("y", d => d.length === 0 ? 0 : this.y(d[d.length - 1][1] + d[d.length - 1][0]) / 2)
          .attr("fill", d => color(d.key))
          .attr("font-size", 10)
          .attr("font-weight", 'bold')
          .attr("text-anchor", 'start')
          .style('opacity', 1)
          .text(d => themeLabels[d.key]),
        update => update
          .transition()
          .duration(300)
          .style('opacity', d => state.highlight && (!state.highlight.items || !state.highlight.items.includes(d.key)) ? 0.2 : 1)
          .transition()
          .duration(1000)
          .attr("y", d => d.length === 0 ? 0 : this.y(d[d.length - 1][1] + d[d.length - 1][0]) / 2),
      )
  }
}