/* eslint-disable eqeqeq */
import React, { useState } from "react"
import { Row, Col } from 'antd'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from '@react-hook/window-size'

import PresidentChart from '../charts/PresidentChart/PresidentChartComponent'
import politiStats from '../../assets/data/politiStats.json'
import Section from './Section'
import SectionEvolution from './SectionEvolution'

gsap.registerPlugin(ScrollTrigger);

const reponseIcon = <svg width="35" height="25" style={{ marginBottom: -8}} ><path d="M456,49.5 L110,49.5 C83.7664744,49.5 62.5,70.7664744 62.5,97 L62.5,238 L62.5063636,238.785499 C62.9258262,264.656728 84.0288096,285.5 110,285.5 L140.417,285.5 L140.417269,351.101958 L140.420641,351.336081 C140.599947,357.54372 147.890692,360.88307 152.715959,356.865834 L238.435,285.5 L456,285.5 C482.233526,285.5 503.5,264.233526 503.5,238 L503.5,97 C503.5,70.7664744 482.233526,49.5 456,49.5 Z" style={{ fill: 'none', transform: 'scale(0.06)', stroke: 'black', strokeWidth: 40 }}></path></svg>
const twitterIcon = <svg width="25" height="25" style={{ marginBottom: -8}} ><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578 9.3 9.3 0 0 1-2.958 1.13 4.66 4.66 0 0 0-7.938 4.25 13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568 4.692 4.692 0 0 1-2.104.08 4.661 4.661 0 0 0 4.352 3.234 9.348 9.348 0 0 1-5.786 1.995 9.5 9.5 0 0 1-1.112-.065 13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41z" style={{ fill: 'rgb(29, 161, 242)' }}></path></svg>

function BadgeList() {
  const [width, height] = useWindowSize();
  const [withComments, setWithComments] = useState(false);
  const [sectionsProgress, setSectionsProgress] = useState({});

  const setSectionProgress = (id, progress) => {
    sectionsProgress[id] =  { progress };
    setSectionsProgress({...sectionsProgress});
    if(sectionsProgress.sectionB && sectionsProgress.sectionB.progress > 0) {
      setWithComments(true)
    } else {
      setWithComments(false)
    }
  }

  return (
    <div style={{ marginBottom: 40, position: 'relative' }}>
      <div className="graphic" >
        <Row type="flex">
          <Col span={ width >= 900 ? 22 : 22} offset={ width >= 900 ? 0 : 1}>
            <h1>Activité et popularité des présidents</h1>
            {/*<span>Commentaires inclus <Switch checked={withComments} onChange={setWithComments} checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />}/></span>*/}
          </Col>
        </Row>
        <PresidentChart
          smallScreen={width < 900}
          height={height - (width >= 900 ? 100 : 110)} 
          data={politiStats} 
          year={null} 
          withComments={withComments}
          showActivity={(sectionsProgress.sectionA && sectionsProgress.sectionA.progress > 0) && !(sectionsProgress.sectionD && sectionsProgress.sectionD.progress > 0)}
          showPopularity={(sectionsProgress.sectionD && sectionsProgress.sectionD.progress > 0)}
        />
      </div>
      <Row type="flex" justify="space-between">
        <Col span={width < 900 ? 20 : 12} offset={width < 900 ? 2 : 6}>
          <div style={{ zIndex: 1000, position: 'relative' }}>
            <Section id='sectionA' setSectionProgress={setSectionProgress} >
              <h3>Les plus prolifiques: tweets originaux {twitterIcon}</h3>
              <p>Il faut distinguer les tweets “originaux” et les réponses à des tweets émis par d’autres. Sur les trois dernières années, le trio de tête en nombre de tweets postés est formé par <strong>Georges-Louis Bouchez</strong> (MR), <strong>Peter Mertens</strong> (PTB-PVDA) et <strong>Raoul Hedebouw</strong> (qui n’est pas président de parti, mais porte la voix du PTB au sud du pays).</p>
            </Section>
            <Section id='sectionB' setSectionProgress={setSectionProgress} style={{ marginBottom: '50vh' }} >
              <h3>Les plus prolifiques: tweets {twitterIcon} et réponses {reponseIcon}</h3>
              <p>Les présidents répondent également aux tweets des internautes. Georges-Louis Bouchez le fait massivement, que les messages lui soient adressés ou non. Il est au moins quatre fois plus actif sur Twitter que ses opposants. Ces trois dernières années, il a rédigé plus de 5000 réponses à des tweets.</p>
              <p>Au point de se voir imposer un silence radio dans le courant du mois de septembre, quand les négociations pour la formation d’un gouvernement Vivaldi entraient dans leur dernière ligne droite.</p>
            </Section>
            <SectionEvolution id='sectionEvolution' setSectionProgress={setSectionProgress} />
            <Section id='sectionD' setSectionProgress={setSectionProgress} style={{ marginBottom: '50vh' }}>
              <h3>Les plus populaires</h3>
              <p>On peut tweeter peu et être en même temps très populaire sur le réseau. C’est la stratégie de <strong>Bart De Wever</strong>. Le président de la N-VA poste très peu de messages... mais chacune de ses sorties est massivement “likée” (763 likes en moyenne par tweet).</p>
              <p>De manière générale, les comptes Twitter des présidents de parti trouvent davantage d’écho au nord du pays. Les deuxième et troisième marches du podium sont occupées respectivement par <strong>Tom Van Grieken</strong> (Vlaams Belang) et <strong>Conner Rousseau</strong> (dont la communication se fait davantage sur Instagram... <a target="_blank" rel="noopener noreferrer" alt="insta" href="https://www.rtbf.be/info/belgique/detail_des-photos-du-president-du-sp-a-a-une-soiree-dansante-en-france-conner-rousseau-ferme-son-compte-instagram?id=10550802">avec parfois des conséquences pour son image</a>). </p>            
            </Section>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BadgeList;
