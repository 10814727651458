import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PresidentChart from './PresidentChart';
import { injectIntl } from 'react-intl';

class PresidentChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    if (chart && (
      this.props.withComments !== nextProps.withComments
      || this.props.showActivity !== nextProps.showActivity
      || this.props.showPopularity !== nextProps.showPopularity)
      ) {
      chart.update(nextProps);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const elWidth = el.offsetWidth;
    const height = this.props.height || elWidth;
    const margin = {
      top: 20,
      right: 0,
      bottom: 40,
      left: 0,
    };

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: height - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new PresidentChart(el, props),
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(this.props);
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div ref='chart' />
    );
  }
}

export default injectIntl(PresidentChartComponent)