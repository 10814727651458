import React, { useRef, useEffect, useState} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import _ from 'lodash'

import LineChart from "../charts/LineChart/LineChartComponent";
import politiStats from '../../assets/data/politiStats.json'

gsap.registerPlugin(ScrollTrigger);

const glb = politiStats.find(p => p.name === 'GLBouchez')

export default function Section({ id, setSectionProgress }) {
  const sectionRef = useRef(null);
  const [progress, setProgress] = useState(0) 

  useEffect(() => {
    gsap.fromTo(sectionRef.current, {
      autoAlpha: 1
    }, {
      duration: 1, 
      autoAlpha: 1,
      ease: 'none',
      scrollTrigger: {
        id,
        trigger: sectionRef.current,
        start: 'center 75%',
        end: 'center 25%',
        toggleActions: 'play none none reverse',
        //onEnter: () => setActiveSection(id),
        //onLeave: () => setIsActive(false),
        //onEnterBack: () => setActiveSection('step1'),
        //onLeaveBack: () => setIsActive(false),
        onUpdate: (self) => {
          setProgress(self.progress)
          setSectionProgress(id, self.progress)
        },
        //markers: true,
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="story-section" ref={sectionRef}>
      <h3>Le moment charnière</h3>
      <p>L’activité de Georges-Louis Bouchez sur Twitter s’est fortement intensifiée au mois d’avril 2016 où son volume de tweets atteint près d’un millier de messages par mois.</p>
      <p>A l’époque, il a perdu son siège de député au Parlement Wallon et se positionne comme le premier opposant d’Elio Di Rupo à Mons. Ils livreront bataille aussi bien au conseil communal de Mons que sur les réseaux sociaux.</p>
      <LineChart 
        timeSeries={[
          {
            name: 'Tweets',
            data: _(glb.monthly)
              .map(d => ({ date: new Date(d.date), value: d.count}))
              .orderBy('date', 'asc')
              .value(),
            color: '#1da1f2',
            area: true,
          },
          {
            name: 'Réponses',
            data: _(glb.monthly)
              .map(d => ({ date: new Date(d.date), value: d.responsesCount}))
              .orderBy('date', 'asc')
              .value(),
            color: 'black',
            area: false,
          }
        ]}
        progress={progress} 
      />
    </div>
  );
}