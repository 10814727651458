import React, { useState } from "react"
import { Row, Col } from 'antd'
import { useWindowSize } from '@react-hook/window-size'
import moment from 'moment'

import BumpChart from '../charts/BumpChart/BumpChartComponent'
import BumpChartVertical from '../charts/BumpChartVertical/BumpChartComponent'
import Section from '../Sections/Section'
import themeEvolution from '../../assets/data/themeEvolution.json'
import './TweetThemes.css'

function TweetThemesGlobal() {
  const [width, height] = useWindowSize();
  //const [normalize, setNormalize] = useState(null)
  const [sectionsProgress, setSectionsProgress] = useState({});
  const [highlight, setHighlight] = useState({});

  const setSectionProgress = (id, progress) => {
    sectionsProgress[id] =  { progress };
    setSectionsProgress({...sectionsProgress});
    if(sectionsProgress['sectionA'] && sectionsProgress['sectionA'].progress > 0 && sectionsProgress['sectionA'].progress < 1) {
      setHighlight({ items: [themeEvolution.keys[Math.floor(themeEvolution.keys.length * sectionsProgress['sectionA'].progress)]], dates: null})
    } else if(sectionsProgress['sectionB'] && sectionsProgress['sectionB'].progress > 0 && sectionsProgress['sectionB'].progress < 1) {
      setHighlight({ items: ['écologie'], dates: [moment('2018-04-01'), moment('2019-04-01')]})
    } else if(sectionsProgress['sectionC'] && sectionsProgress['sectionC'].progress > 0 && sectionsProgress['sectionC'].progress < 1) {
      setHighlight({ items: null, dates: [moment('2018-12-01'), moment('2019-05-01')]})
    } else if(sectionsProgress['sectionD'] && sectionsProgress['sectionD'].progress > 0 && sectionsProgress['sectionD'].progress < 1) {
      setHighlight({ items: ['coronavirus', 'santé'], dates: [moment('2020-01-01'), moment('2021-01-01')]})
    } else {
      setHighlight(null)
    }
  }

  return (
    <div style={{ marginBottom: 40 }}>
      { width < 900 &&
        <div style={{ padding: width >= 900 ? 0 : 16 }}>
          <h1>De quoi parlent-ils ?</h1>
          <p>Quelles sont les thématiques les plus présentes dans le discours des présidents de parti ? Que veulent-ils que les journalistes (très présents sur Twitter) et les citoyens retiennent de leurs idées ?</p>
        </div>
      }
      <div className="graphic" >
        { width >= 900 &&
          <div style={{ padding: width >= 900 ? 0 : 16 }}>
            <h1>De quoi parlent-ils ?</h1>
            <p>Quelles sont les thématiques les plus présentes dans le discours des présidents de parti ? Que veulent-ils que les journalistes (très présents sur Twitter) et les citoyens retiennent de leurs idées ?</p>
          </div>
        }
        {/*<Row type="flex" justify="end">
          <div>{normalize ? 'Vue relative' : 'Vue absolue'} <Switch checked={normalize} onChange={setNormalize} /></div>
          </Row>*/}
        { width >= 900 &&
          <BumpChart 
            data={{ 
              ...themeEvolution, 
              values: themeEvolution.values
                .filter(d => moment(d.date).isSameOrAfter('2018-01-01'))
            }} 
            //normalize={normalize}
            height={height * 0.85} 
            highlight={highlight}
          />
        }
        { width < 900 &&
          <BumpChartVertical 
            data={{ 
              ...themeEvolution, 
              values: themeEvolution.values
                .filter(d => moment(d.date).isSameOrAfter('2018-01-01'))
            }} 
            //normalize={normalize}
            height={height* 0.83} 
            highlight={highlight}
          />
        }
      </div>
      <Row type="flex" justify="space-between">
        <Col span={width < 900 ? 20 : 12} offset={width < 900 ? 2 : 6}>
          <div style={{ zIndex: 1000, position: 'relative' }}>
            <Section id='sectionA' setSectionProgress={setSectionProgress} >
              <h3>Les thèmes principaux</h3>
              <p>Ce graphique montre l'évolution des sujets abordés au fil des mois. Chaque bande de couleur est associée à une thématique. L’épaisseur de la bande représente le nombre de tweets. La position indique l’ordre d’importance des thématiques durant la période. On voit par exemple que la politique est presque toujours le thème le plus présent.</p>
            </Section>
            <Section id='sectionB' setSectionProgress={setSectionProgress} >
              <p><strong>Ecologie.</strong> Durant les campagnes de 2018 et 2019, la question de l’écologie a agité les débats. Le thème est porteur : le 27 janvier 2019, <a target="_blank" rel="noopener noreferrer" href="https://www.rtbf.be/info/societe/detail_des-dizaines-de-milliers-de-personnes-a-nouveau-attendues-a-bruxelles-pour-le-climat?id=10129506">70.000 personnes participent à une marche pour le climat à Bruxelles</a>. En avril, <a target="_blank" rel="noopener noreferrer" href="https://www.rtbf.be/info/belgique/detail_barometre-politique-d-ou-vient-la-vague-verte-ecolo-peut-il-encore-mieux-faire?id=10203538">un sondage prédit une augmentation des votes en faveur des écologistes</a>. On retrouve cette “vague verte” dans les tweets.</p>            
            </Section>
            <Section id='sectionC' setSectionProgress={setSectionProgress} style={{ marginBottom: '50vh' }} >
              <p><strong>Emploi et Pension.</strong> Pendant la campagne des élections de 2019, les présidents de parti ont été particulièrement présents sur Twitter, et le nombre de tweets a explosé. Durant cette période, les sujets économiques ont pris une ampleur considérable dans les débats. Age du départ à la retraite, portefeuille des Belges, tax shift... pour attirer les électeurs, chaque parti <a target="_blank" rel="noopener noreferrer" href="https://www.rtbf.be/info/belgique/detail_elections-federales-2019-les-mesures-phares-des-partis-et-ce-qu-elles-devraient-couter-selon-le-bureau-du-plan?id=10205362">y est allé de ses propositions</a>.</p>
            </Section>
            <Section id='sectionD' setSectionProgress={setSectionProgress} style={{ marginBottom: '50vh' }} >
              <p><strong>Santé.</strong> Longtemps mise de côté, la question des soins de santé est arrivée sur le devant de la scène dans le courant du mois de février 2020. En pleine pandémie de Covid-19, alors qu’un confinement inédit est mis en place, le secteur dénonce un manque de moyens. <a target="_blank" rel="noopener noreferrer" href="https://www.rtbf.be/info/belgique/detail_en-colere-le-personnel-de-l-hopital-saint-pierre-litteralement-tourne-le-dos-a-sophie-wilmes?id=10503539" >Au point de tourner physiquement le dos à Sophie Wilmès (MR), alors Première ministre, en mai dernier</a>.</p>
            </Section>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TweetThemesGlobal;
