import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import LineChart from './LineChart.js';

class LineChartComponent extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps){
    var currentState = this.getChartState(nextProps);
    if(this.state.chart){
      this.state.chart.update(currentState);
    }
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);

    if (this.state.chart) {
      this.state.chart.destroy();
    }

    const margin = {
      top: 25,
      right: 25,
      bottom: 20,
      left: 5
    };

    const elWidth = el.clientWidth;
    const elHeight = this.props.height || 150;

    const props = {
      margin: margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new LineChart(el, props)
    }, function() {
      this.state.chart.create();
      this.state.chart.update(this.getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  getChartState(props) {
    return {
      timeSeries: props.timeSeries,
      progress: props.progress
    };
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    //TODO understand why this isn't needed
    //this.state.chart.destroy();
    window.removeEventListener('resize', this.createChart);
  }

  render() {
    return (
      <div ref='chart'></div>
    );
  }
};

export default LineChartComponent;