import Chart from '../chart';
import * as d3 from 'd3';
import moment from 'moment'

import './LineChart.css'

export default class LineChart extends Chart {

  create() {
    this.svg = super.createRoot();

    this.main = this.svg.append('g')
      .attr('class', 'main linechart');

    this.evolutions = this.main.append('g')
      .attr('class', 'evolutions')

    this.xAxis = this.main.append('g')
      .attr('class', 'xAxis')
      .attr('transform', `translate(0,${this.props.height})`)
    
    this.yAxis = this.main.append('g')
      .attr('class', 'yAxis')
      .attr('transform', `translate(${this.props.width},0)`)

    this.marker = this.main.append('g')
      .attr('class', 'marker') 

    this.legend = this.main.append('g')
      .attr('class', 'legend') 
    
    this.legend
      .append('text')
      .attr('font-size', 12)
      .attr('font-weight', '900')
      .attr('fill', '#1da1f2')
      .attr('x', 10)
      .attr('y', -15)
      .text('— Tweets')

    this.legend
      .append('text')
      .attr('font-size', 12)
      .attr('font-weight', '900')
      .attr('fill', 'black')
      .attr('x', 70)
      .attr('y', -15)
      .text('— Réponses')
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if(state.timeSeries !== null){
      this.drawChart(state);
    }
  }

  drawChart(state) { 
    const { timeSeries, progress } = state

    const fullData = timeSeries.reduce((all, t) => all.concat(t.data), []);
    const onProgressData = timeSeries.reduce((all, t) => all.concat(t.data.slice(0, Math.round(t.data.length * progress))), []) 

    const xEvolution = d3.scaleTime()
      .domain(d3.extent(fullData, d => d.date))
      .range([0, this.props.width]).nice()
    const yEvolution = d3.scaleLinear()
      .domain([0, d3.max(onProgressData, d => d.value)]).nice()
      .range([this.props.height, 0])
    const xAxis = d3.axisBottom(xEvolution)
      .ticks(5)
      .tickSizeOuter(0)
    const yAxis = d3.axisRight(yEvolution)
      .ticks(4)
      .tickSizeOuter(0)
    
    this.xAxis
      .call(xAxis)

    this.yAxis
      //.call(g => g.select(".domain").remove())
      .transition()
      .duration(100)
      .call(yAxis)
      //.call(g => g.select(".domain").remove())
    
    const line = d3.line()
      .defined(d => !isNaN(d.value))
      .x(d => xEvolution(d.date))
      .y(d => yEvolution(d.value))
      .curve(d3.curveMonotoneX)
    
    const area = d3.area()
      .defined(d => !isNaN(d.value))
      .x(d => xEvolution(d.date))
      .y0(yEvolution(0))
      .y1(d => yEvolution(d.value))
      .curve(d3.curveMonotoneX)
    
    this.evolutions.selectAll('.evolution')
      .data(timeSeries)
      .join(
        enter => enter.append('g')
          .attr('class', 'evolution')
          .call( enter => enter.append('path')
            .attr('class', 'line')
            .attr("fill", "none")
            .attr("stroke", d => d.color)
            .attr("stroke-width", this.props.width/150)
            .attr("d", d => line(d.data.slice(0, Math.round(d.data.length * progress))))
          )
          .call( enter => enter.append('path')
            .attr('class', 'area')
            .attr("fill", d => d.color)
            .style("opacity", d => d.area ? 0.1 : 0)
            .attr("d", d => area(d.data.slice(0, Math.round(d.data.length * progress))))
          ),
        update => update
          .call( update => update.select('.line')
            .transition()
            .duration(100)
            .attr("d", d => line(d.data.slice(0, Math.round(d.data.length * progress))))
          )
          .call( update => update.select('.area')
            .transition()
            .duration(100)
            .attr("d", d => area(d.data.slice(0, Math.round(d.data.length * progress))))
        )
      )

    this.marker.selectAll('.marker')
    .data([moment('2016-05-01').toDate()])
    .join(
      enter => enter.append('g')
        .attr('class', 'marker')
        .call( enter => enter.append('line')
          .attr('class', 'marker-line')
          .attr("x1", d => xEvolution(d))
          .attr("x2", d => xEvolution(d))
          .attr("y1", 0)
          .attr("y2", this.props.height)
          .attr("stroke", 'tomato')
          .attr("stroke-width", this.props.width/200)
        )
        .call( enter => enter.append('circle')
          .attr('class', 'marker-circle')
          .attr("cx", d => xEvolution(d))
          .attr("cy", -5)
          .attr("stroke", 'tomato')
          .attr("stroke-width", this.props.width/200)
          .attr("r", 5)
          .attr("fill", 'white')
        )
        .call( enter => enter.append('text')
          .attr('class', 'marker-line')
          .attr("x", d => xEvolution(d) + 10)
          .attr("y", -2)
          .attr("font-size", 12)
          .text("Avril 2016")
          .attr("fill", 'tomato')
        ),
      update => update
        .call( update => update.select('.marker-line')
          .transition()
          .duration(100)
          .attr("opacity", 1)
        )
    )
  }
}