import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './Story.css'

gsap.registerPlugin(ScrollTrigger);

export default function Section({ children, id, setSectionProgress }) {
  const sectionRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(sectionRef.current, {
      autoAlpha: 0,
    }, {
      duration: 1, 
      autoAlpha: 1,
      ease: 'none',
      scrollTrigger: {
        id,
        trigger: sectionRef.current,
        start: 'top 85%',
        end: 'top 15%',
        toggleActions: 'play none none reverse',
        //onEnter: () => setActiveSection(id),
        onLeave: () => setSectionProgress(null),
        //onEnterBack: () => setActiveSection('step1'),
        onLeaveBack: () => setSectionProgress(null),
        onUpdate: (self) => setSectionProgress(id, self.progress),
        //markers: true,
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="story-section" ref={sectionRef}>
      {children}
    </div>
  );
}