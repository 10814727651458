import React from "react"
//import { Row, Col } from 'antd'
import { useWindowWidth } from '@react-hook/window-size'

function Intro() {
  const width = useWindowWidth({wait: 1});

  return (
    <div>
      <div style={{ paddingLeft: width >= 900 ? '15vw' : 16, paddingRight: width >= 900 ? '15vw' : 16, marginTop: '0vh', marginBottom: '8vh' }}>
        <blockquote className={width >= 900 ? 'blockquote-large' : 'blockquote-small'}>Les citoyens utilisent les réseaux sociaux pour parler de politique. Je pense que les réseaux sociaux ne sont pas faits pour ça.</blockquote>
        <h3>La phrase est de Mathieu Michel (MR), secrétaire d’Etat à la digitalisation au sein du gouvernement De Croo. Que penserait Georges-Louis Bouchez, son président de parti omniprésent sur Twitter, de cette affirmation ? Et ses adversaires politiques qui ont fait des réseaux sociaux un lieu de débats parfois animés ?</h3>
        <h3>En Belgique, les dernières campagnes électorales et les négociations pour la formation d’un gouvernement fédéral se sont en partie jouées à coups de messages sur Twitter. Au point que ce qui se passait sur ce réseau a parfois eu une influence sur le cours de l’actualité. Chaque président de parti s’est fait sa propre utilisation du réseau, tweetant lui-même ou laissant son équipe de communication s’en charger.</h3>
        <h3>Nous nous sommes intéressés à la manière dont chacun utilisait son compte Twitter : qui tweete le plus ? A quelle heure ? Sur quel sujet ? Qui est le plus populaire ? Quelles sont les différentes stratégies de communication utilisées ?</h3>
        <h3>Pour répondre à ces questions, nous avons extrait tous les messages postés par les présidents de partis belges sur le réseau social entre janvier 2018 et septembre 2020. Plus de 20.000 tweets, des milliers de likes et de retweets... Entre clashs, coups de com' et buzz, voici ce que nous apprend cette énorme masse de données.</h3>
      </div>
    </div>
  );
}

export default Intro;