/* eslint-disable eqeqeq */
import React, { useState } from "react"
import { Row, Col } from 'antd'
import { useWindowWidth } from '@react-hook/window-size'
import * as d3 from 'd3'
import _ from 'lodash';
import Slider from "react-slick";

import BadgeChart from '../charts/BadgeChart/BadgeChartComponent'
import politiStats from '../../assets/data/politiStats.json'
import { presidentInfo } from '../charts/BadgeChart/BadgeChart'
import swipe from '../../assets/images/swipe-helper.gif'

function BadgeList() {
  const width = useWindowWidth({wait: 1});
  const [presidents] = useState(politiStats);
  const maxCount = _.maxBy(politiStats, 'full.count').full.count
  const [dateRange] = useState(d3.extent(_(politiStats)
    .map('monthly')
    .flatMap()
    .map(m => new Date(m.date))
    .value())
  )

  return (
    <div style={{ marginBottom: 40, padding: 8 }}>
      <h1 style={{marginBottom: 32}}>Les présidents en un coup d'œil</h1>
      <Slider dots={true} infinite={true} speed={500} slidesToShow={width < 900 ? 1 : 3} slidesToScroll={1} arrows={true}>
        { presidents && _(presidents)
            .orderBy(d => d.full.count + d.full.responsesCount, 'desc')
            //.filter(d => !['GLBouchez', 'Bart_DeWever'].includes(d.name))
            .value()
            .map(president => (
              <Row key={president.name} type="flex" justify="start">
                <Col span={24} style={{textAlign: 'center' }}>
                  <h2>{presidentInfo[president.name].name}</h2>
                  <h4 style={{ marginBottom: 32, marginTop: -8 }}>{presidentInfo[president.name].party}</h4>
                  <BadgeChart 
                    data={president} 
                    maxCount={maxCount} 
                    dateRange={dateRange} 
                    year={null}//{selectedYear === 'null' ? null : selectedYear}
                  />
                </Col>
              </Row>
            )
          )
        }
      </Slider>
      {width < 900 && 
        <div style={{ textAlign: 'center', marginTop: 32 }}>
          <img src={swipe} style={{width: '15vw'}} alt="swipe"/>
        </div>
      }
      {width >= 900 && 
        <div style={{ height: '10vh' }}>
        </div>
      }
    </div>
  );
}

export default BadgeList;
